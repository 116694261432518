import * as d3 from "d3";
import chroma from "chroma-js";

const defaultSimulationOptions = {
  linkDistance: 80,
  linkStrength: 0.5,
  repulsivity: 200,
  collisionRadius: 25,
  centeringStrength: 0.1,
  distanceMin: 50,
  distanceMax: 1500
};

const getDisplayValue = node => {
  const nodeVal = node.id.split("/").pop();
  if (nodeVal === "STUD_1743") {
    return "Michael";
  }
  return nodeVal;
};

export const drawD3Chart = ({
  graph: { nodes: n, edges },
  width,
  height,
  el,
  id,
  withLabels,
  linkColor,
  isZoomable = true,
  simulationOptions = {},
  radius = 20
}) => {
  const simOptions = {
    radius: height,
    ...defaultSimulationOptions,
    ...simulationOptions
  };

  const links = edges;
  const nodes = n;

  const simulation = d3
    .forceSimulation(nodes)
    .alphaTarget(0)
    .alphaDecay(0.05)
    .force('x', d3.forceX(width / 2).strength(0.1))
    .force('y', d3.forceY(height / 2).strength(0.1))
    .force(
      "link",
      d3
        .forceLink(links)
        .id((d) => d.id)
        .strength(simOptions.centeringStrength)
    )
    .force(
      "charge",
      d3
        .forceManyBody()
        .strength(-simOptions.repulsivity)
        .distanceMin(simOptions.distanceMin)
        .distanceMax(simOptions.distanceMax)
    )
    .force("radial", d3.forceRadial(simOptions.radius, width / 2, height / 2))
    .force("collide", d3.forceCollide(simOptions.collisionRadius))
    .force("center", d3.forceCenter(width / 2, height / 2));

  const dragstarted = (d) => {
    if (!d3.event.active) simulation.alphaTarget(0.3).restart();
    d.fy = d.y;
    d.fx = d.x;
  };

  const dragged = (d) => {
    d.fx = d3.event.x;
    d.fy = d3.event.y;
  };

  const dragended = (d) => {
    if (!d3.event.active) simulation.alphaTarget(0);
    d.fx = null;
    d.fy = null;
  };

  const d3RootEl = d3.select(el.current);
  d3RootEl.selectAll("*").remove();

  const svg = d3RootEl.append("svg").attr("viewBox", [0, 0, width, height]);

  svg
    .append("mask")
    .attr("id", `${id}-frame-mask`)
    .append("rect")
    .attr("width", width)
    .attr("height", height)
    .attr("rx", 16)
    .attr("fill", "#fff");

  svg
    .append("g")
    .append("rect")
    .attr("width", width)
    .attr("height", height)
    .attr("rx", 16)
    .attr("fill", "#271D59");

  if (!n || !edges) {
    return;
  }

  const legendMetadata = [
    { name: "Aptitude", color: "#EFACD1" },
    { name: "Course", color: "#778DBA" },
    { name: "Volunteering", color: "#BD7C7A" },
    { name: "Activity", color: "#df6b79" },
    { name: "Interest", color: "#6089d4" },
    { name: "Extracurricular", color: "#DEC89B" },
    { name: "Student", color: "#A0CF8C" }
  ];

  const legend = svg.append("g");
  legend.selectAll(".legend-items")
    .data(legendMetadata)
    .enter()
    .append("circle")
    .attr("fill", d => d.color)
    .attr("r", 3)
    .attr("cx", width - 90)
    .attr("cy", (d, i) => 20 + i * 10);

  legend.selectAll(".legend-text")
    .data(legendMetadata)
    .enter()
    .append("text")
    .attr("fill", "#fff")
    .attr("font-size", "6px")
    .text(d => d.name)
    .attr("dy", 2)
    .attr("x", width - 90 + 10)
    .attr("y", (d, i) => 20 + i * 10);

  const g = svg.append("g");

  const tooltip = d3
    .select("body")
    .append("div")
    .style("position", "absolute")
    .style("z-index", "10000")
    .style("display", "none")
    .classed("ttip", true);

  svg
    .append("defs")
    .append("marker")
    .attr("id", "arrowhead")
    .attr("viewBox", "-0 -5 10 10")
    .attr("refX", 32)
    .attr("refY", 0)
    .attr("orient", "auto")
    .attr("markerWidth", 8)
    .attr("markerHeight", 8)
    .attr("xoverflow", "visible")
    .append("svg:path")
    .attr("d", "M 0,-3 L 6 ,0 L 0,3")
    .attr("fill", "#999")
    .style("stroke", "none");

  const link = g
    .append("g")
    .attr("stroke", "#aaa")
    .attr("stroke-opacity", 0.3)
    .selectAll("line")
    .data(links)
    .join("line")
    .attr("marker-end", "url(#arrowhead)")
    .attr("stroke-width", (d) => 1);

  const globalNode = g.append("g");

  const edgepaths = globalNode
    .selectAll(".edgepath")
    .data(links)
    .enter()
    .append("path")
    .attr("class", "edgepath")
    .attr("fill-opacity", 0)
    .attr("stroke-opacity", 0)
    .attr("id", (d, i) => `${id}-edgepath${i}`)
    .style("pointer-events", "none");

  const edgelabelroot = globalNode
    .selectAll(".edgelabel")
    .data(links)
    .enter()
    .append("g");

  const edgelabels = edgelabelroot
    .append("text")
    .style("pointer-events", "none")
    .attr("class", "edgelabel")
    .attr("id", (d, i) => `${id}-edgelabel${i}`)
    .attr("font-size", 4)
    .attr("fill", "#B9DCF9")
    .attr("dy", 6);

  edgelabels
    .append("textPath")
    .attr("xlink:href", (d, i) => `#${id}-edgepath${i}`)
    .style("text-anchor", "middle")
    .style("pointer-events", "none")
    .attr("startOffset", "50%")
    .attr("visibility", withLabels ? "visible" : "hidden")
    .text((d) => d.label);

  const node = globalNode
    .append("g")
    .selectAll("circle")
    .data(nodes)
    .join("circle")
    .attr("r", radius)
    .attr("fill", (d) => chroma(d.color || "#6F8DCA").brighten(0))
    .attr("stroke", (d) => chroma(d.color || "#6F8DCA").darken(1.5))
    .attr("stroke-width", 2)
    .call(
      d3
        .drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended)
    )
    .on("mouseover", function(d) {
      tooltip.text(getDisplayValue(d));
      const pos = d3.select(this).node().getBoundingClientRect();
      tooltip
        .style("display", "")
        .style("left", `${pos["x"]}px`)
        .style("top", `${window.scrollY + pos["y"] - 20}px`);
    })
    .on("mousemove", function(event) {
    })
    .on("mouseout", function() {
      return tooltip.style("display", "none");
    });

  const textElements = g
    .append("g")
    .selectAll("text")
    .data(nodes)
    .join("text")
    .attr("class", () => "node-text")
    .text((node) => getDisplayValue(node))
    .attr("font-size", 6)
    .attr("font-family", "Lato")
    .attr("fill", function(d) {
      const { color } = d;
      const chromaColor = chroma(color || "#9f9f9f");
      if (chromaColor.luminance() > 0.5) {
        return chromaColor.darken(2);
      }
      return chromaColor.brighten(2);
    })
    .attr("fill-opacity", 1)
    .attr("dx", -radius + 5)
    .attr("dy", 0)
    .attr("pointer-events", "none")
    .call(truncateText);

  simulation.on("tick", () => {
    link
      .attr("x1", (d) => d.source.x)
      .attr("y1", (d) => d.source.y)
      .attr("x2", (d) => d.target.x)
      .attr("y2", (d) => d.target.y);

    node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);
    textElements.attr("x", (d) => d.x).attr("y", (d) => d.y);

    edgepaths.attr(
      "d",
      (d) =>
        "M " +
        d.source.x +
        " " +
        d.source.y +
        " L " +
        d.target.x +
        " " +
        d.target.y
    );
  });

  let zoomLvl = 0;
  let lastK = 0;

  const zoomed = () => {
    const e = d3.event;

    if (e.transform.k > 2 && lastK !== e.transform.k) {
      lastK = e.transform.k;
      zoomLvl = Math.log2(e.transform.k);
      globalNode.attr("stroke-width", 1.5 / zoomLvl);
      link.attr("stroke-width", (d) => (20 + d.weight) * 0.01);
    }

    g.attr("transform", e.transform);
  };
  const zoom = d3
    .zoom()
    .extent([
      [0, 0],
      [width, height]
    ])
    .scaleExtent([-80, 80])
    .on("zoom", zoomed);

  svg.call(zoom);

  function reset() {
    svg.transition().duration(750).call(
      zoom.transform,
      d3.zoomIdentity,
      d3.zoomTransform(svg.node()).invert([width / 2, height / 2])
    );
  }

  return {
    zoomIn: () => svg.transition().call(zoom.scaleBy, 2),
    zoomOut: () => svg.transition().call(zoom.scaleBy, 0.5),
    zoomReset: reset
  };
};

const truncateText = (text) => {
  text.each(function() {
    const delimiter = "";
    const text = d3.select(this);
    const letters = text.text().split(delimiter);
    const original = text.text();
    const ellipsis = text
      .text("")
      .append("tspan")
      .attr("class", "elip")
      .text("...");
    const width = 30;
    const numLetters = letters.length;

    const tspan = text
      .insert("tspan", ":first-child")
      .text(letters.join(delimiter));

    while (tspan.node().getComputedTextLength() > width && letters.length) {
      letters.pop();
      tspan.text(letters.join(delimiter));
    }

    text.append("title").text(original);

    if (letters.length === numLetters) {
      ellipsis.remove();
    }
  });
};
