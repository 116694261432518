import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@emotion/react";

import "./index.css";
import "./overrides.css";
import { Entry } from "Entry";
import { theme } from "theme";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Entry />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
