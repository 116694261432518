/** @jsxImportSource @emotion/react */
import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { InfoBlock } from "../info-block/InfoBlock";
import avatar from "../../svg/icons/avatar.svg";
import { TextSkeleton } from "../text-skeleton/TextSkeleton";
import { IconSkeleton } from "../icon-skeleton/IconSkeleton";
import { HeaderLayout } from "../header-layout/HeaderLayout";

const ProfileLayout = styled.figure`
  display: flex;
  gap: 20px;
  margin: 0;
`;

const LegendLayout = styled.figcaption`
  display: flex;
  width: 100%;
  flex-basis: 80%;
`;

const layout = [
  ["64", "64", "64", "64"],
  ["80", "80", "80", "56"],
];

export const SmartProfile = () => (
  <InfoBlock withTilt>
    <HeaderLayout>
      <h4>Smart Profile</h4>
      <IconSkeleton />
    </HeaderLayout>
    <ProfileLayout>
      <img src={avatar} alt="avatar-icon" />
      <LegendLayout>
        {layout.map((block, i) => (
          <div key={i} css={css`flex-basis: 50%;`}>
            {block.map((element, i) => (
              <TextSkeleton key={`${element}-${i}`} value={element} />
            ))}
          </div>
        ))}
      </LegendLayout>
    </ProfileLayout>
  </InfoBlock>
);
