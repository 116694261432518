/** @jsxImportSource @emotion/react */
import React from "react";
import Parallax from "react-rellax";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useMediaQuery } from "@mantine/hooks";

import polkaDot from "svg/background-parallax-layers/dotted-pattern.svg";
import circleIcon from "svg/background-parallax-layers/circle.svg";
import arrowInSingle from "svg/icons/arrow-in-single.svg";
import arrowIn from "svg/icons/arrow-in.svg";
import ioData from "data/inputs-outputs";
import arrowOrange from "svg/icons/arrow-orange.svg";
import arrowGreen from "svg/icons/arrow-green.svg";
import arrowCurvedIn from "svg/illustrations/4-how-it-works/arrow-curved-in.svg";
import logo from "svg/logos/logo.svg";
import arrowCurvedOut from "svg/illustrations/4-how-it-works/arrow-curved-out.svg";
import arrowOut from "svg/icons/arrow-out.svg";
import arrowDownIn from "svg/icons/arrow-down-in.svg";
import arrowDownOut from "svg/icons/arrow-down-out.svg";

import { Jumbotron } from "../1-get-to-know/GetToKnowPage";
import { SM_BP, XS_BP, XL_BP } from "constants";

const Root = styled.section`
  background-color: #fff;
  z-index: 1;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentRoot = styled.div`
  width: 65vw;
  @media (max-width: ${XS_BP}) {
    width: 85vw;
  }
`;

const IOTable = styled.div`
  display: flex;
  align-items: center;
  margin-top: 35px;
  gap: 30px;
  padding-bottom: 100px;

  @media (max-width: ${XS_BP}) {
    flex-direction: column;
    padding-bottom: 20px;
  }
`;

const Block = styled.div`
  flex-basis: 37%;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${(props) => props.theme.colors["text-main"]};

  & figure {
    display: flex;
    margin: 0;
    gap: 20px;
    align-items: center;
    align-self: flex-start;
  }

  & figure div img:first-of-type {
    position: absolute;
  }

  & figcaption {
    display: flex;
    flex-direction: column;
  }

  & figcaption h2 {
    font-size: 38px;
    font-weight: 700;
    color: #6066a3;
    margin: 0 0 10px 0;
  }

  & figcaption p {
    font-size: 16px;
    font-weight: 700;
    color: #6066a3;
    margin: 0;
  }

  & ul {
    list-style-type: none;
    padding: 0;
  }

  & li {
    padding: 0;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  & li img {
    margin-right: 12px;
  }
`;

const MiddleGraphics = styled.div`
  flex-basis: 26%;
  display: flex;
  flex-direction: column;

  .middle-logo {
    display: flex;
    flex-direction: column;

    img {
      height: 120px;
    }

    p {
      text-align: center;
      font-size: 16px;
      line-height: 28px;
      color: #404040;
    }
  }

  & > img:first-of-type {
    margin-left: -100px;
    margin-top: -40px;
  }

  & > img:last-of-type {
    margin-right: -100px;
    margin-bottom: -100px;
  }
`;

export const HowItWorksPage = () => {
  const isFullLayout = useMediaQuery(`(min-width: ${SM_BP})`);
  const isXLLayout = useMediaQuery(`(min-width: ${XL_BP})`);
  const graphicsSize = isXLLayout ? "130px" : isFullLayout ? "auto" : "91px";
  return (
    <Root>
      <Parallax
        speed={-0.55}
        css={css`
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: -1;
          @media (max-width: ${XS_BP}) {
            display: none;
          }
        `}
      >
        <img src={polkaDot} alt="background-pattern-1" />
      </Parallax>
      <Parallax
        speed={0}
        css={css`
          position: absolute;
          bottom: 0;
          right: 0;
          z-index: -1;
          @media (max-width: ${XS_BP}) {
            display: none;
          }
        `}
      >
        <img src={circleIcon} alt="background-pattern-2" />
      </Parallax>
      <ContentRoot>
        <Jumbotron
          css={css`
            width: 100%;
            @media (max-width: ${SM_BP}) {
              & p {
                text-align: left;
              }
            }
          `}
        >
          <h2>
            How <span className="accented">AYO</span> works
          </h2>
          <p>
            <span className="accented">AYO</span> makes connections with
            existing applications within schools. These connections are used to
            collect data from the resources that students and educators use
            every day. <span className="accented">AYO</span> also collects data
            directly, based on user interactions with{" "}
            <span className="accented">AYO</span> (surveys, user feedback,
            findings validation, etc).
          </p>
        </Jumbotron>
        <IOTable>
          <Block>
            <figure>
              <div
                css={css`
                  position: relative;
                `}
              >
                <img
                  className="landing-strip-animation"
                  src={arrowInSingle}
                  alt="animation-element-arrow"
                />
                <img src={arrowIn} alt="icon-arrows-in" />
              </div>
              <figcaption>
                <h2>In</h2>
                <p>Data Sources</p>
              </figcaption>
            </figure>
            <div>
              <ul>
                {ioData.inputs.map((item) => (
                  <li key={item}>
                    <img src={arrowOrange} alt={`select-option-${item}`} />
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </Block>
          <MiddleGraphics>
            <img
              css={css`
                height: ${graphicsSize};
              `}
              src={isFullLayout ? arrowCurvedIn : arrowDownIn}
              alt="graphics-arrow-in"
            />
            <div className="middle-logo">
              <img
                src={logo}
                className="logo-animation"
                alt="ayo-logo-animated"
              />
              <p>
                AYO Artificial <br />
                Intelligence Processing
              </p>
            </div>
            <img
              css={css`
                height: ${graphicsSize};
              `}
              src={isFullLayout ? arrowCurvedOut : arrowDownOut}
              alt="graphics-arrow-out"
            />
          </MiddleGraphics>
          <Block>
            <figure>
              <div
                css={css`
                  position: relative;
                `}
              >
                <img
                  className="landing-strip-animation"
                  src={arrowInSingle}
                  alt="animation-element-arrow"
                />
                <img src={arrowOut} alt="" />
              </div>
              <figcaption>
                <h2>Out</h2>
                <p>Insights</p>
              </figcaption>
            </figure>
            <div>
              <ul>
                {ioData.outputs.map((item) => (
                  <li key={item}>
                    <img src={arrowGreen} alt={`select-option-${item}`} />
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </Block>
        </IOTable>
      </ContentRoot>
    </Root>
  );
};
