/** @jsxImportSource @emotion/react */
import React from "react";
import Parallax from "react-rellax";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { reasons } from "data/reasons";
import { Paragraph } from "elements/paragraph/Paragraph";

import ellipse2 from "svg/background-parallax-layers/ellipse-2.svg";
import ellipse from "svg/background-parallax-layers/ellipse-1.svg";
import triangle from "svg/background-parallax-layers/triangle.svg";
import triangle2 from "svg/background-parallax-layers/triangle-2.svg";
import { XS_BP } from "constants";

const Root = styled.section`
  background-color: #6066a3;
  z-index: -1;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 80px;
`;

const ContentRoot = styled.div`
  width: 65vw;
  padding-top: 140px;
  @media (max-width: ${XS_BP}) {
    width: 90vw;
    padding-top: 60px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 4;

  h2 {
    text-align: center;
    color: #fff;
  }
`;

const ReasonsBlock = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: ${XS_BP}) {
    flex-direction: column;
    gap: 10px;
  }

  & > div {
    flex-basis: 50%;
  }
`;

export const WhyPage = () => {
  return (
    <Root>
      <Parallax
        speed={0}
        css={css`
          left: -150px;
          top: 0;
          position: absolute;
          z-index: 0;
        `}
      >
        <img
          src={ellipse2}
          style={{ maxWidth: "100%" }}
          alt="background-layer-icon-ellipse"
        />
      </Parallax>
      <Parallax
        speed={0}
        factor={1}
        css={css`
          right: 0;
          bottom: -10px;
          z-index: 0;
          position: absolute;
        `}
      >
        <img
          src={ellipse}
          style={{ maxWidth: "100%" }}
          alt="background-layer-icon-ellipse"
        />
      </Parallax>
      <Parallax
        speed={0.75}
        factor={1}
        css={css`
          top: 90px;
          right: 140px;
          z-index: 0;
          position: absolute;
          @media (max-width: ${XS_BP}) {
            display: none;
          }
        `}
      >
        <img src={triangle} alt="background-layer-icon-triangle" />
      </Parallax>
      <Parallax
        speed={2.1}
        factor={1}
        css={css`
          top: 470px;
          right: 110px;
          z-index: 0;
          position: absolute;
          @media (max-width: ${XS_BP}) {
            display: none;
          }
        `}
      >
        <img src={triangle2} alt="background-layer-icon-triangle-2" />
      </Parallax>
      <Parallax
        speed={1}
        className="content"
        css={css`
          z-index: 4;
        `}
      >
        <ContentRoot>
          <Content>
            <h2>Why AYO?</h2>
            <ReasonsBlock>
              {reasons.map((block, i) => (
                <div key={i}>
                  {block.map((item) => (
                    <Paragraph
                      key={item.headline}
                      icon={item.icon}
                      headline={item.headline}
                      text={item.text}
                    />
                  ))}
                </div>
              ))}
            </ReasonsBlock>
          </Content>
        </ContentRoot>
      </Parallax>
    </Root>
  );
};
