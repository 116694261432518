export const theme = {
  radius: 16,
  colors: {
    "primary@500": "#6065A8",
    "primary@300": "#878BC8",
    "primary@050": "#F2F3FF",
    "text-accent-primary": "#6066A3",
    "text-accented": "#303359",
    "text-interactive": "#6065A8",
    "text-interactive-selected": "#2F325C",
    "text-main": "#404040"
  },
  spacing: {
    gap: 16,
    cardBox: "32px 24px",
  },
  typography: {
    sizing: {
      button: 16,
    },
  },
};
