import { useMemo } from "react";

const interestValueToKey = {
  0: "not interested",
  33: "neutral",
  66: "like it",
  99: "love it",
};

export const useFormPreprocessor = (formValue, interests) => {
  const value = useMemo(
    () => ({
      student_info: {
        STUDENT_ID: [formValue.student_id],
        STUDENT_GENDER: ["Male"],
        STUDENT_GRADE: [formValue.student_grade || 8],
      },
      test_scores: formValue.test_scores,
      user_selected_interests: Object.keys(interests)
        .map((interestKey) => ({
          interest: interestKey,
          reaction: interestValueToKey[interests[interestKey]],
        }))
        .filter((x) => x.reaction),
      google_search_queries: formValue.google_search_queries,
      essays: formValue.essays,
    }),
    [formValue, interests]
  );

  return {
    value,
  };
};
