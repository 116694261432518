import illustration from "../svg/illustrations/3-opportunity/illustration.svg";
import students from "../svg/illustrations/3-opportunity/students.svg";
import success from "../svg/illustrations/3-opportunity/success.svg";
import teachers from "../svg/illustrations/3-opportunity/teachers.svg";
import mentorship from "../svg/illustrations/3-opportunity/mentorship.svg";
import parents from "../svg/illustrations/3-opportunity/parents.svg";

export default {
  list: [
    {
      image: illustration,
      link: "Scattered data on one canvas",
      headline: "Scattered data on one canvas",
      explanation: (
        <p>
          <span className="accented">AYO</span> pulls the data from numerous
          systems in the districts and gathers everything in one place helping
          build a holistic student profile. Moreover,{" "}
          <span className="accented">AYO</span> enriches the data with valuable
          insights from students themselves, their parents, and teachers.
        </p>
      ),
    },
    {
      image: students,
      link: "Student agency over their learning",
      headline: "Student agency over their learning",
      explanation: (
        <p>
          With <span className="accented">AYO</span>, students have all the
          insights, tools, and empowerment they need to make informed decisions
          and choices throughout their learning journey.
        </p>
      ),
    },
    {
      image: success,
      link: "Student success in and outside of school",
      headline: "Student success in and outside of school",
      explanation: (
        <p>
          <span className="accented">AYO</span> provides numerous opportunities
          for students to explore their interests and aptitudes, building skills
          and gaining experiences to prepare them for their future whatever path
          they decide to choose.
        </p>
      ),
    },
    {
      image: teachers,
      link: "Holistic view of the student",
      headline: "Holistic view of the student",
      explanation: (
        <p>
          A massive amount of data comes together in a simple informative
          student profile in <span className="accented">AYO</span>, and teachers
          are able to make use of their students’ profiles in their daily
          work.
        </p>
      ),
    },
    {
      image: mentorship,
      link: "Shift for coaching and mentoring",
      headline: "Shift for coaching and mentoring",
      explanation: (
        <p>
          With access to holistic student profiles, teachers are able to
          shift their focus from traditional instruction to coaching and
          mentoring, thus tailoring their teaching methods to the needs of each
          individual student.
        </p>
      ),
    },
    {
      image: parents,
      link: "Insights for parents",
      headline: "Insights for parents",
      explanation: (
        <p>
          With <span className="accented">AYO</span>, parents have a quick
          and easy way to see where their children's aptitudes and interests
          lie, how their leadership skills develop, and what potential
          opportunities are worth exploring.
        </p>
      ),
    },
  ],
};
