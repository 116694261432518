import React from "react";
import styled from "@emotion/styled";

const colors = {
  purple: "#6066a3",
  yellow: "#f3aa47",
};

const BarRoot = styled.div`
  position: relative;
  background: #6066a314;
  height: 3px;
  width: 100%;
  border-radius: 16px;
  margin-top: 1rem;
  margin-bottom: 22px;

  & .base {
    position: absolute;
    left: 0;
    background: #6066a3;
    width: 100% !important;
    opacity: 0.2;
    height: 3px;
    border-radius: 16px;
  }
  
  & .highlight {
    will-change: width;
    position: absolute;
    left: 0;
    background: ${props => colors[props.color]};
    width: ${props => props.value};
    height: 3px;
    border-radius: 16px;
    transition: width 0.5s ease;
  }
`;

export const Bar = ({ color, value }) => (
  <BarRoot color={color} value={value}>
    <div className="base" />
    <div className="highlight" />
  </BarRoot>
);
