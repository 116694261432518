/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useRef, useState, memo } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import {
  useElementSize,
  useDisclosure,
  useViewportSize,
  useMediaQuery
} from "@mantine/hooks";
import { Modal, Tooltip } from "@mantine/core";
import { ShepherdTourContext } from "react-shepherd";

import { Network } from "elements/network-chart/NetworkChart";
import { TagContainer } from "elements/tag-container-with-overflow/TagContainer";

import modalIcon from "svg/icons/modal-icon.svg";
import aptitudes from "svg/icons/aptitudes.svg";
import interests from "svg/icons/interests.svg";
import expandIcon from "svg/icons/fullscreen.svg";
import infoIcon from "svg/icons/info.svg";
import tourIconWhite from "svg/icons/tour.svg";
import tourIconMain from "svg/icons/tour-filled.svg";

import { useGraphData } from "features/graph/useGraphData";
import { StudentForm } from "features/forms/StudentForm";
import { Button } from "elements/button/Button";
import { XS_BP } from "constants";
import { Block } from "elements/block/Block";

const Root = styled.section`
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #f2f3ff 0%, #fffbf6 100%);
  position: relative;
`;

const cardHeaderStyle = css`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-family: "Lato", sans-serif;
  position: relative;

  img {
    margin-right: 8px;
  }

  h3 {
    margin: 0;
    text-transform: uppercase;
    font-size: 12px;
  }
`;

const ContentRoot = styled.div`
  width: 65vw;
  display: flex;
  flex-direction: column;

  & > h2:first-of-type {
    color: ${(props) => props.theme.colors["text-accented"]};
    text-align: center;
    margin-bottom: 0;
  }

  & > p:first-of-type {
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    color: ${(props) => props.theme.colors["text-main"]};
  }

  header.card-header {
    ${cardHeaderStyle};
  }

  @media (max-width: ${XS_BP}) {
    width: 90vw;
  }
`;

const InputRoot = styled.section`
  width: 50%;
  @media (max-width: ${XS_BP}) {
    width: 100%;
  }
`;

const OutputRoot = styled.section`
  width: 50%;
  padding: 0 16px 16px 16px;
  box-sizing: border-box;

  .card-body {
    max-height: 136px;
  }

  .targets-block {
    width: 100%;
    margin-top: 16px;
    max-height: 150px;

    & > div {
      max-width: 100%;
      align-items: center;
    }
  }

  @media (max-width: ${XS_BP}) {
    width: 100%;
  }
`;

export const DemoPage = memo(({ onTourOpen, onTourClose }) => {
  const matches = useMediaQuery(`(min-width: ${XS_BP})`);
  const { ref: baseChartRef, width: baseChartWidth } = useElementSize();
  const [opened, handlers] = useDisclosure(false);
  const { fetchData, error, isLoading, graph, getMock } = useGraphData(
    process.env.REACT_APP_ML_SERVICE
  );
  const { height, width } = useViewportSize();
  const [hasShownTour, setHasShownTour] = useState(false);
  const [tourInProg, setTourInProg] = useState(false);
  const nodeRef = useRef();
  const chartPreviewRef = useRef();
  const chartMainRef = useRef();
  const tour = useContext(ShepherdTourContext);

  const { ref, width: w } = useElementSize();
  const { ref: interestsBlockRef, width: interestsW } = useElementSize();

  const onDiscard = () => {
    setTourInProg(false);
    onTourClose();
  };

  useEffect(() => {
    tour.on("cancel", onDiscard);
    tour.on("complete", onDiscard);
    return () => {
      tour.off("cancel", onDiscard);
      tour.off("complete", onDiscard);
    };
  }, [tour]);

  const onOutputTargetsClick = () => {
    handlers.open();
  };

  return (
    <Root>
      {matches && (
        <div
          onClick={() => {
            if (tourInProg) {
              tour.cancel();
              setTourInProg(false);
            } else {
              tour.start();
              setHasShownTour(true);
              setTourInProg(true);
              onTourOpen();
            }
          }}
          css={css`
            z-index: 9998;
            cursor: pointer;
            position: sticky;
            left: 85vw;
            top: 85vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          `}
        >
          <Button
            className={!hasShownTour ? "pulsating" : ""}
            css={css`
              background: ${tourInProg ? "#fff" : "#FFA726"};
              border-color: ${tourInProg ? "#fff" : "#FFA726"};
              border-radius: 100%;
              margin-bottom: 4px;
              width: 46px;
              height: 46px;
            `}
          >
            <img
              src={tourInProg ? tourIconMain : tourIconWhite}
              alt="tour-launch"
            />
          </Button>
          <span
            css={css`
              font-weight: 700;
              font-size: 14px;
              color: ${tourInProg ? "#fff" : "#FFA726"};
            `}
          >
            {tourInProg ? "Close tour" : "Watch tour"}
          </span>
        </div>
      )}

      <ContentRoot>
        <h2>See for yourself</h2>
        <p>
          To get a sneak peek into <span className="accented">AYO</span>'s
          functionality, feel free to change the data we know about Michael
          below and see how that affects his Aptitudes, Interests, and Potential
          Targets on the right. Click the Tour button to see how that works.
        </p>
        <div
          css={css`
            display: flex;
            gap: 10px;
            @media (max-width: ${XS_BP}) {
              flex-direction: column;
            }
          `}
        >
          <InputRoot>
            <StudentForm isBusy={isLoading} onSubmit={fetchData} error={error} />
          </InputRoot>
          <OutputRoot>
            <header
              css={css`
                font-family: "Lato", sans-serif;
                font-size: 12px;
                font-weight: 700;
                text-transform: uppercase;
                color: #404040;
                padding: 16px 8px;
                line-height: 16px;
                margin-bottom: 16px;
              `}
            >
              Insights
            </header>
            <div ref={nodeRef} css={css`
              margin-bottom: 16px;
            `}>
              <Block
                withTilt={false}
                innerStyle={{ height: "100%", marginBottom: 12 }}
                style={{ height: "100%" }}
                id="potential-targets"
              >
                <header className="card-header">
                  <img src={modalIcon} alt=""/>
                  <h3>Potential Targets & Categories</h3>
                  <Tooltip
                    styles={{
                      body: {
                        padding: 12,
                        background: "#303359",
                        borderRadius: "16px",
                        fontSize: 10,
                        color: "#fff",
                        width: 130
                      }
                    }}
                    css={css`
                      position: absolute;
                      right: -8px;
                      cursor: pointer;
                    `}
                    label="This map is interactive so you can check connections among the elements."
                  >
                    <img src={infoIcon} alt="info-icon"/>
                  </Tooltip>
                </header>
                <div
                  css={css`
                    width: 100%;
                    position: relative;
                  `}
                  ref={baseChartRef}
                >
                  <Button
                    css={css`
                      position: absolute;
                      z-index: 1;
                      top: 10px;
                      left: 10px;
                      border: none;
                      padding: 0;
                      background: transparent;

                      & img {
                        height: 24px;
                      }
                    `}
                    onClick={onOutputTargetsClick}
                  >
                    <img src={expandIcon} alt="expand-icon"/>
                  </Button>
                  {!opened && (<button
                      css={css`
                        position: absolute;
                        z-index: 1;
                        top: 40px;
                        left: 10px;
                        height: 24px;
                        width: 24px;
                        color: #6066A3;
                        font-size: 12px;
                        border-radius: 6px;
                        background: #ECF6FF;
                        border: none;
                        cursor: pointer;
                      `}
                      onClick={() => {
                        chartPreviewRef.current.zoomIn();
                      }}>
                      +
                    </button>
                  )}
                  {!opened && (<button
                      css={css`
                        position: absolute;
                        z-index: 1;
                        top: 68px;
                        left: 10px;
                        height: 24px;
                        width: 24px;
                        color: #6066A3;
                        font-size: 12px;
                        border-radius: 6px;
                        background: #ECF6FF;
                        border: none;
                        cursor: pointer;
                      `}
                      onClick={() => {
                        chartPreviewRef.current.zoomOut();
                      }}>
                      -
                    </button>
                  )}
                  {!opened && (
                    <Network
                      ref={chartPreviewRef}
                      width={baseChartWidth}
                      height={140}
                      withLabels={false}
                      isZoomable
                      simulationOptions={{ repulsivity: 50, radius: 112 * 2 }}
                      graph={graph}
                    />
                  )}
                  <div className="targets-block" ref={ref}>
                    <TagContainer itemWidth={w - 64} items={graph.potential_targets}/>
                  </div>
                </div>
              </Block>
            </div>
            <div
              css={css`
                display: flex;
                margin-bottom: 16px;
                gap: 16px;
                width: 100%;
              `}
            >
              <Block
                css={css`
                  width: 50%;
                `}
                withTilt={false}
                id="aptitudes"
              >
                <header className="card-header">
                  <img src={aptitudes} alt=""/>
                  <h3>Aptitudes</h3>
                </header>
                <div className="card-body">
                  <TagContainer items={graph.aptitudes} itemWidth={interestsW - 36} />
                </div>
              </Block>
              <Block
                css={css`
                  width: 50%;
                `}
                withTilt={false}
                id="interests"
              >
                <header className="card-header">
                  <img src={interests} alt=""/>
                  <h3>Interests</h3>
                </header>
                <div className="card-body" ref={interestsBlockRef}>
                  <TagContainer itemWidth={interestsW - 36} items={graph.interests}/>
                </div>
              </Block>
            </div>
          </OutputRoot>
        </div>
        <Modal
          zIndex={9999}
          size={width * 0.85}
          styles={{
            title: {
              width: "100%",
              alignItems: "center"
            }
          }}
          title={
            <header
              css={css`
                ${cardHeaderStyle};
                margin-bottom: 0;
              `}
            >
              <img src={modalIcon} alt=""/>
              <h3>Potential Targets & Categories</h3>

              <Tooltip
                  zIndex={10000}
                  styles={{
                    body: {
                      padding: 12,
                      background: "#303359",
                      borderRadius: "16px",
                      fontSize: 10,
                      color: "#fff",
                      width: 130
                    }
                  }}
                  css={css`
                      position: absolute;
                      right: -25px;
                      top: 0px;
                      cursor: pointer;
                    `}
                  label="This map is interactive so you can check connections among the elements."
              >
                <img src={infoIcon} alt="info-icon"/>
              </Tooltip>
            </header>
          }
          overlayColor="#000"
          overlayOpacity={0.5}
          centered
          opened={opened}
          onClose={() => handlers.close()}
          radius={16}
        >
          <div css={css`
            position: relative;
          `}>
            <Network
              ref={chartMainRef}
              width={width * 0.5}
              height={height * 0.45}
              withLabels
              isZoomable
              simulationOptions={{
                repulsivity: 300
              }}
              graph={graph}
            />
            <div css={css`
              position: absolute;
              z-index: 1;
              top: 0;
              bottom: 0;
              right: 12px;
              margin: auto;
              height: calc(48px + 4px);
              display: flex;
              flex-direction: column;
              gap: 4px;
            `}>
              <button
                css={css`
                  height: 24px;
                  width: 24px;
                  color: #6066A3;
                  font-size: 14px;
                  border-radius: 6px;
                  background: #ECF6FF;
                  border: none;
                  cursor: pointer;
                `}
                onClick={() => {
                  chartMainRef.current.zoomIn();
                }}>
                +
              </button>
              <button
                css={css`
                  height: 24px;
                  width: 24px;
                  color: #6066A3;
                  font-size: 14px;
                  border-radius: 6px;
                  background: #ECF6FF;
                  border: none;
                  cursor: pointer;
                `}
                onClick={() => {
                  chartMainRef.current.zoomOut();
                }}>
                -
              </button>
            </div>

          </div>
        </Modal>
      </ContentRoot>
    </Root>
  );
});
