export const getSliderStyles = () => ({
  track: {
    backgroundColor: "#878BC8",
  },
  mark: {
    backgroundColor: "transparent",
    border: 0,
  },
  markFilled: {
    backgroundColor: "transparent",
    border: 0,
  },
  markLabel: {
    fontSize: 8,
    width: 45,
    transform: "translateX(-10px) translateY(12px)",
  },
  thumb: {
    height: 36,
    width: 36,
    fontSize: 30,
    borderWidth: 0,
  },
});
