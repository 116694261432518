import tourIntro from "svg/illustrations/6-demo/tour.svg";

export default [
  {
    arrow: false,
    id: "intro",
    buttons: [
      {
        classes: "tour-button-cancel",
        text: "Skip the tour",
        type: "cancel",
      },
      {
        classes: "tour-button-primary",
        text: "Let's go",
        type: "next",
      },
    ],
    cancelIcon: {
      enabled: false,
    },
    title: null,
    text: () => {
      const el = document.createElement("div");
      el.insertAdjacentHTML(
        "beforeend",
        `
                <div>
                    <img src="${tourIntro}" alt="tour-intro-image" style="max-width: 100%;" /> 
                    <h3 class="tour-headline">Here is a quick walk-through of the AYO demo functionality.</h3>
                </div>
              `
      );
      return el;
    },
  },
  {
    arrow: false,
    id: "1",
    modalOverlayOpeningPadding: 20,
    modalOverlayOpeningRadius: 16,
    attachTo: {
      element: "#student-info",
      on: "right-start",
    },
    scrollTo: true,
    buttons: [
      {
        classes: "tour-button-cancel",
        text: "Skip the tour",
        type: "cancel",
      },
      {
        classes: "tour-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    classes: "left-padding",
    cancelIcon: {
      enabled: false,
    },
    title: "Student info",
    text: () => {
      const el = document.createElement("div");
      const stepText =
        "In this section you can find basic information about Michael. The data has been pre-filled for your convenience. Also, you can change and adjust data as you wish, and every time you submit changes here, the dashboard on the right will adjust.";
      el.insertAdjacentHTML(
        "beforeend",
        `
              <div>
                <h4 class="sub-headline">INPUT 1/4</h4>
                <p>${stepText}</p>
              </div>
            `
      );
      return el;
    },
  },
  {
    arrow: false,
    id: "2",
    attachTo: {
      element: "#test-scores",
      on: "right-start",
    },
    modalOverlayOpeningPadding: 20,
    modalOverlayOpeningRadius: 16,
    buttons: [
      {
        classes: "tour-button-cancel",
        text: "Skip the tour",
        type: "cancel",
      },
      {
        classes: "tour-button-prev",
        text: "Back",
        type: "back",
      },
      {
        classes: "tour-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    cancelIcon: {
      enabled: false,
    },
    classes: "left-padding",
    title: "Test scores",
    text: () => {
      const el = document.createElement("div");
      const stepText =
        "Here, you can see Michael's test scores in various subjects. Try changing the scores and see how his aptitudes on the right also change.";
      el.insertAdjacentHTML(
        "beforeend",
        `
              <div>
                <h4 class="sub-headline">INPUT 2/4</h4>
                <p>${stepText}</p>
              </div>
            `
      );
      return el;
    },
  },
  {
    arrow: false,
    id: "3",
    attachTo: {
      element: "#web-search-history",
      on: "right-start",
    },
    modalOverlayOpeningPadding: 20,
    modalOverlayOpeningRadius: 16,
    classes: "left-padding",
    cancelIcon: {
      enabled: false,
    },
    buttons: [
      {
        classes: "tour-button-cancel",
        text: "Skip the tour",
        type: "cancel",
      },
      {
        classes: "tour-button-prev",
        text: "Back",
        type: "back",
      },
      {
        classes: "tour-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    title: "Web search history",
    text: () => {
      const el = document.createElement("div");
      const stepText =
        "This section consists of Michael's web search history at the campus. The spaces have been pre-filled with text, however, you can delete it and add your text to check how his interests and aptitudes change.";
      el.insertAdjacentHTML(
        "beforeend",
        `
              <div>
                <h4 class="sub-headline">INPUT 3/4</h4>
                <p>${stepText}</p>
              </div>
            `
      );
      return el;
    },
  },
  {
    id: "4",
    arrow: false,
    attachTo: {
      element: "#essays",
      on: "right-start",
    },
    classes: "left-padding",
    modalOverlayOpeningPadding: 20,
    modalOverlayOpeningRadius: 16,
    cancelIcon: {
      enabled: false,
    },
    buttons: [
      {
        classes: "tour-button-cancel",
        text: "Skip the tour",
        type: "cancel",
      },
      {
        classes: "tour-button-prev",
        text: "Back",
        type: "back",
      },
      {
        classes: "tour-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    title: "Essays",
    text: () => {
      const el = document.createElement("div");
      const stepText =
        "Here, you can find Michael's essays, analysed by AYO. The content in the boxes can easily be changed and the changes will impact the output on the right.";
      el.insertAdjacentHTML(
        "beforeend",
        `
              <div>
                <h4 class="sub-headline">INPUT 4/4</h4>
                <p>${stepText}</p>
              </div>
            `
      );
      return el;
    },
  },
  {
    id: "5",
    arrow: false,
    attachTo: {
      element: "#aptitudes",
      on: "left-start",
    },
    classes: "right-padding",
    modalOverlayOpeningPadding: 0,
    modalOverlayOpeningRadius: 16,
    cancelIcon: {
      enabled: false,
    },
    buttons: [
      {
        classes: "tour-button-cancel",
        text: "Skip the tour",
        type: "cancel",
      },
      {
        classes: "tour-button-prev",
        text: "Back",
        type: "back",
      },
      {
        classes: "tour-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    title: "Aptitudes",
    text: () => {
      const el = document.createElement("div");
      const stepText =
        "Based on the information in the previous sections that AYO has analysed, now you can see the results. Here, you can find Michael's aptitudes, as suggested by AYO.";
      el.insertAdjacentHTML(
        "beforeend",
        `
              <div>
                <h4 class="sub-headline">OUTPUT 1/3</h4>
                <p>${stepText}</p>
              </div>
            `
      );
      return el;
    },
  },
  {
    id: "6",
    arrow: false,
    attachTo: {
      element: "#interests",
      on: "left-start",
    },
    classes: "right-padding",
    modalOverlayOpeningPadding: 0,
    modalOverlayOpeningRadius: 16,
    cancelIcon: {
      enabled: false,
    },
    buttons: [
      {
        classes: "tour-button-cancel",
        text: "Skip the tour",
        type: "cancel",
      },
      {
        classes: "tour-button-prev",
        text: "Back",
        type: "back",
      },
      {
        classes: "tour-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    title: "Interests",
    text: () => {
      const el = document.createElement("div");
      const stepText =
        "In this section, you can see Michael's interests, as analysed and suggested by AYO, based on the information on the left.";
      el.insertAdjacentHTML(
        "beforeend",
        `
              <div>
                <h4 class="sub-headline">OUTPUT 2/3</h4>
                <p>${stepText}</p>
              </div>
            `
      );
      return el;
    },
  },
  {
    id: "7",
    arrow: false,
    attachTo: {
      element: "#potential-targets",
      on: "left-start",
    },
    classes: "right-padding",
    modalOverlayOpeningPadding: 0,
    modalOverlayOpeningRadius: 16,
    cancelIcon: {
      enabled: false,
    },
    buttons: [
      {
        classes: "tour-button-prev",
        text: "Back",
        type: "back",
      },
      {
        classes: "tour-button-primary",
        text: "Got it",
        type: "next",
      },
    ],
    title: "Potential targets & categories",
    text: () => {
      const el = document.createElement("div");
      const stepText =
        "And finally, you have reached the Potential section, where you can check Michel's potential targets and categories, as well as connections between them. \n" +
        "The map here is interactive, we encourage you to explore it.";
      el.insertAdjacentHTML(
        "beforeend",
        `
              <div>
                <h4 class="sub-headline">OUTPUT 3/3</h4>
                <p>${stepText}</p>
              </div>
            `
      );
      return el;
    },
  },
];
