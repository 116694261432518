/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { useWindowScroll } from "@mantine/hooks";
import styled from "@emotion/styled";

import { InfoBlock } from "../info-block/InfoBlock";
import { TextSkeleton } from "../text-skeleton/TextSkeleton";
import { Bar } from "../bar/Bar";
import { HeaderLayout } from "../header-layout/HeaderLayout";
import { Flex } from "../flex/Flex";

const LayoutLeft = styled.div`
  flex-basis: 40%;
`;

const LayoutRight = styled.div`
  flex-basis: 60%;
`;

export const barPositions = [
  ["80%", "30%", "45%", "85%"],
  ["20%", "80%", "70%", "45%"],
];

const leftGridLayout = ["80", "80", "80", "80"];

export const SmartPath = () => {
  const [scroll] = useWindowScroll();
  const [[a, b, c, d], setValues] = useState(barPositions[0]);

  useEffect(() => {
    if (scroll.y > 100) {
      setValues(barPositions[1]);
    } else {
      setValues(barPositions[0]);
    }
  }, [scroll.y]);

  return (
    <InfoBlock withTilt>
      <HeaderLayout>
        <h4>Potential Discovery</h4>
        <TextSkeleton value={"12"} />
      </HeaderLayout>
      <Flex>
        <LayoutLeft>
          {leftGridLayout.map((item, i) => (
            <TextSkeleton key={`${i}-${item}`} value={item} />
          ))}
        </LayoutLeft>
        <LayoutRight>
          <Bar color="purple" value={a} />
          <Bar color="yellow" value={b} />
          <Bar color="yellow" value={c} />
          <Bar color="purple" value={d} />
        </LayoutRight>
      </Flex>
    </InfoBlock>
  );
};
