/** @jsxImportSource @emotion/react */
import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

const Pill = styled.div`
  margin: 2px 4px 2px 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  border-radius: 18px;
  padding: 4px 8px;
  font-size: 14px;
`;

export const MultiselectInputPill = ({
  value,
  onRemove,
  background,
  color,
}) => (
  <Pill background={background} color={color}>
    <div>{value}</div>
    <div onClick={onRemove} css={css`font-size: 16px; cursor: pointer;`}>
      &times;
    </div>
  </Pill>
);
