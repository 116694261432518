/** @jsxImportSource @emotion/react */
import React, { useRef } from "react";
import styled from "@emotion/styled";
import { NumberInput as MantineNumberInput } from "@mantine/core";

import { useTheme } from '@emotion/react'

import arrowDown from "../../../svg/icons/arrow-down.svg";

const Root = styled.div`
  display: inline-block;
  position: relative;
`;

const IconUp = styled.div`
  position: absolute;
  top: 22px;
  right: 5px;
  transform: rotate(180deg);
  cursor: pointer;
`;

const IconDown = styled.div`
  top: 42px;
  position: absolute;
  right: 5px;
  cursor: pointer;
`;

export const NumberInput = ({ label, style, styles, height, containerStyle, ...rest }) => {
  const handlers = useRef();
  const theme = useTheme();
  return (
    <Root style={containerStyle}>
        <MantineNumberInput
            hideControls
            handlersRef={handlers}
            label={label}
            styles={{
                input: {
                    borderRadius: `${theme.radius}px`,
                    border: "1px solid #E4E4E7",
                    color: theme.colors["text-main"],
                    height: height || 40,
                },
                label: {
                    color: theme.colors["text-accent-primary"],
                    transform: "translateY(13px) translateX(12px)",
                    zIndex: 3,
                    fontWeight: 300,
                    fontSize: 12,
                },
                ...styles,
            }}
            style={{ ...style }}
            {...rest}
        />
        <IconUp onClick={() => handlers.current.increment()}>
            <img src={arrowDown} alt="icon-input-increase"/>
        </IconUp>
        <IconDown onClick={() => handlers.current.decrement()}>
            <img src={arrowDown} alt="icon-input-descrease"/>
        </IconDown>
    </Root>
  );
};
