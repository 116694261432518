import styled from "@emotion/styled";

export const Flex = styled.div`
  display: flex;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`;
