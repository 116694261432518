import { useReducer } from "react";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";

const initialState = {
  isSending: false,
  isSuccess: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "start":
      return {
        ...state,
        isSending: true,
      };
    case "success":
      return {
        isSending: false,
        isSuccess: true,
      };
    case "error":
      return {
        isSending: false,
        isSuccess: false,
      };
    default:
      throw new Error();
  }
};

export const useRequestDemoForm = () => {
  const [opened, handlers] = useDisclosure(false);
  const form = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      company: "",
      jobTitle: "",
      termsAccepted: false,
      subscribedForUpdates: false,
    },
    validate: {
      firstName: (x) => {
        if (!x) {
          return "Please, tell us your First Name."
        }
        if (x.trim().length > 300) {
          return "The entered name can't exceed the 300-character limit."
        }
        return null;
      },
      lastName: (x) => {
        if (!x) {
          return "Please, tell us your Last Name.";
        }
        if (x.trim().length > 300) {
          return "The entered name can't exceed the 300-character limit."
        }
        return null;
      },
      email: (x) =>
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(x)
              ? null
              : "Please, tell us your Email.",
      termsAccepted: (x) =>
          x ? null : "You have to accept the Privacy Policy.",
    },
  });
  const [state, dispatch] = useReducer(reducer, initialState);

  const sendData = () => {
    dispatch({ type: "start" });
    return fetch(
      "https://us-central1-gcp101677-ayodemo.cloudfunctions.net/ayo-demo-form-function",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form.values),
      }
    )
      .then((response) => {
        if (response.ok) {
          dispatch({ type: "success" });
        } else {
          dispatch({ type: "error" });
        }
      })
      .catch(() => {
        dispatch({ type: "error" });
      });
  };

  return {
    opened,
    handlers,
    sendData,
    form,
    ...state,
  };
};
