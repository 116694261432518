/** @jsxImportSource @emotion/react */
import React, { useState, useMemo } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Tooltip } from "@mantine/core";

import values from "data/values";

import bullet from "svg/icons/bullet.svg";
import youtube from "svg/logos/yt-play.svg";
import { XS_BP } from "constants";
import { Link } from "page-layers/8-request-demo/styles";

const Root = styled.section`
  background-color: #fffbf6;
  z-index: 1;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentRoot = styled.div`
  width: 65vw;
  @media (max-width: ${XS_BP}) {
    width: 90vw;
  }
`;

const Content = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;

  & h2 {
    color: ${(props) => props.theme.colors["text-accented"]};
  }
`;

const NavigationTabs = styled.nav`
  width: 100%;
  background: #fff;
  padding: 4px 16px;
  box-sizing: border-box;
  margin-bottom: 50px;
  border-bottom-left-radius: ${(props) => `${props.theme.radius}px`};
  border-bottom-right-radius: ${(props) => `${props.theme.radius}px`};
  border-top-right-radius: ${(props) => `${props.theme.radius}px`};
  border-top-left-radius: ${(props) => `${props.theme.radius}px`};
  z-index: 4;

  @media (max-width: ${XS_BP}) {
    margin-bottom: 10px;
  }

  & > ul {
    padding: 0;
    @media (max-width: 420px) {
      padding-left: 0;
    }

    align-items: center;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    margin: 0;
    font-weight: 700;
  }

  & ul li {
    @media (max-width: ${XS_BP}) {
      padding: 2px;
    }
    height: 20px;
    color: ${(props) => props.theme.colors["text-interactive"]};
    border-radius: ${(props) => props.theme.radius}px;
    border: 2px solid transparent;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: calc(100% / 3);
    cursor: pointer;
    padding: 4px 90px;
    text-align: center;
    transition: all 0.2s ease;
  }

  & ul li.active {
    color: ${(props) => props.theme.colors["text-interactive-selected"]};
    background-color: ${(props) => props.theme.colors["primary@050"]};
    border-color: ${(props) => props.theme.colors["primary@300"]};
  }
`;

const PointList = styled.section`
  display: flex;
  gap: 10px;

  & > div:first-of-type {
    flex-basis: 60%;
  }

  & > div:nth-of-type(2) {
    flex-basis: 40%;
    align-self: center;
  }

  li {
    line-height: 28px;
    display: flex;
    margin-bottom: 16px;
    img {
      margin-right: 22px;
    }
  }

  @media (max-width: ${XS_BP}) {
    gap: 0;

    & > div:first-of-type {
      flex-basis: 100%;
    }
    & > div:nth-of-type(2) {
      display: none;
    }
  }
`;

export const ValueToPage = () => {
  const [selectedTab, setSelectedTab] = useState("students");
  const tabs = useMemo(
    () => [
      {
        id: "students",
        label: "Students",
        tooltipLabel: "Switch here to see AYO value to students",
      },
      {
        id: "educators",
        label: "Educators",
        tooltipLabel: "Switch here to see AYO value to educators",
      },
      {
        id: "parents",
        label: "Parents",
        tooltipLabel: "Switch here to see AYO value to parents",
      },
    ],
    []
  );

  return (
    <Root>
      <ContentRoot>
        <Content>
          <h2>AYO value to: </h2>
          <NavigationTabs>
            <ul>
              {tabs.map((tab) => (
                <li
                  key={tab.id}
                  aria-describedby={`${tab.id}-tab-tooltip`}
                  className={selectedTab === tab.id ? "active" : null}
                  onClick={() => setSelectedTab(tab.id)}
                >
                  <Tooltip
                    position="bottom-start"
                    gutter={2}
                    withinPortal={false}
                    styles={{
                      body: {
                        padding: 12,
                        background: "#303359",
                        borderRadius: "16px",
                        fontSize: 10,
                        color: "#fff",
                        width: 130,
                      },
                    }}
                    label={tab.tooltipLabel}
                    tooltipId={`${tab.id}-tab-tooltip`}
                  >
                    {tab.label}
                  </Tooltip>
                </li>
              ))}
            </ul>
          </NavigationTabs>
          <PointList>
            <div>
              <ul>
                {values[selectedTab].points.map((point, i) => (
                  <li key={`${values[selectedTab].key}-${i}`}>
                    <img src={bullet} alt="bullet-point" />
                    {point}
                  </li>
                ))}
              </ul>
              {selectedTab === "educators" && (
                <Link
                  css={css`
                    padding-left: 40px;
                    margin-bottom: 72px;
                  `}
                >
                  <img src={youtube} alt="youtube" />
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.youtube.com/channel/UCKovJO7d81ZcGqR18C-TOgw"
                  >
                    What are educators saying?
                  </a>
                </Link>
              )}
            </div>
            <div>
              <img src={values[selectedTab].image} alt="tab-illustration" />
            </div>
          </PointList>
        </Content>
      </ContentRoot>
    </Root>
  );
};
