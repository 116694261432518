/** @jsxImportSource @emotion/react */
import React, { useState, useMemo } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useMediaQuery } from "@mantine/hooks";

import { InfoBlock } from "elements/info-block/InfoBlock";

import arrow from "svg/icons/arrow.svg";

import opportunities from "data/opportunities";
import { XS_BP } from "constants";

const Root = styled.section`
  background-color: #f3f4f5;
  z-index: 1;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentRoot = styled.div`
  width: 65vw;
  @media (max-width: ${XS_BP}) {
    width: 100vw;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;

  & h2 {
    margin-top: 38px;
    align-self: flex-start;
    color: ${(props) => props.theme.colors["text-main"]};
  }

  @media (max-width: ${XS_BP}) {
    padding: 0 35px;
  }
`;

const OpportunitySelector = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 103px;

  & > nav {
    display: flex;
    flex-direction: column;
    flex-basis: 55%;
  }

  & > section {
    flex-basis: 45%;
    padding: 15px;
  }

  & ul {
    list-style-type: none;
    padding: 0;
  }

  & li {
    padding: 21px 0;
    border-top: 1px solid #00000014;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.2s ease;

    > p {
      display: flex;
      margin: 0;
      justify-content: space-between;
      width: 100%;
    }
  }

  @media (max-width: ${XS_BP}) {
    & > nav {
      width: 80vw;
      flex-basis: 100%;
    }
    & > section {
      display: none;
    }
    .text p {
      font-weight: 400;
    }
    & li > p {
      &.expanded-paragrpah {
        margin-bottom: 20px;
      }

      img {
        transform: rotate(90deg);
      }
      img.arrow-expanded {
        transform: rotate(270deg);
      }
    }
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 430px;
  overflow: hidden;

  & > img {
    height: 120px;
  }

  & > h4 {
    margin-top: 32px;
    font-size: 24px;
    color: ${(props) => props.theme.colors["text-accented"]};
  }

  & > .text {
    color: ${(props) => props.theme.colors["text-main"]};
    font-size: 16px;
    line-height: 28px;
    overflow-y: hidden;
  }
`;

export const OpportunityPage = () => {
  const [selectedOpportunity, setSelectedOpportunity] = useState(0);
  const isMobileLayout = useMediaQuery(`(max-width: ${XS_BP})`);
  const { image, headline, explanation } = useMemo(
    () => opportunities.list[selectedOpportunity],
    [selectedOpportunity]
  );
  return (
    <Root>
      <ContentRoot>
        <Content>
          <h2>Opportunity</h2>
          <OpportunitySelector>
            <nav>
              <ul>
                {opportunities.list.map((element, i) => (
                  <li
                    key={element.link}
                    onClick={() => setSelectedOpportunity(i)}
                    style={{
                      color: selectedOpportunity === i ? "#6066A3" : "#404040",
                      fontWeight: selectedOpportunity === i ? 700 : 400,
                    }}
                  >
                    <p className={selectedOpportunity === i ? "expanded-paragrpah" : ""}>
                      <span>{element.link}</span>
                      <img
                        className={
                          isMobileLayout && i === selectedOpportunity
                            ? "arrow-expanded"
                            : ""
                        }
                        src={arrow}
                        alt={`select-option-${element.link}`}
                      />
                    </p>
                    {isMobileLayout && i === selectedOpportunity && (
                      <InfoBlock
                        css={css`
                          padding: 0;
                        `}
                      >
                        <CardContent>
                          <img src={image} alt={headline} />
                          <h4>{headline}</h4>
                          <div className="text">{explanation}</div>
                        </CardContent>
                      </InfoBlock>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
            <section>
              <InfoBlock
                css={css`
                  padding: 0;
                `}
                style={{
                  marginBottom: "24px",
                }}
              >
                <CardContent>
                  <img src={image} alt={headline} />
                  <h4>{headline}</h4>
                  <div className="text">{explanation}</div>
                </CardContent>
              </InfoBlock>
            </section>
          </OpportunitySelector>
        </Content>
      </ContentRoot>
    </Root>
  );
};
