/** @jsxImportSource @emotion/react */
import React from "react";
import Parallax from "react-rellax";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import quoteMark from "svg/quote-mark.svg";
import bookImg from "svg/illustrations/5-meet-persona/book-img.svg";
import michael from "svg/illustrations/5-meet-persona/michael.svg";
import ellipse from "svg/background-parallax-layers/ellipse-3.svg";
import { XS_BP } from "constants";

const Root = styled.section`
  background-color: #f3f4f5;
  z-index: 1;
  position: relative;
`;

const Content = styled.div`
  width: 65vw;

  @media (max-width: ${XS_BP}) {
    width: 90vw;
  }

  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;

  h2 {
    color: ${(props) => props.theme.colors["text-accented"]};
    margin-bottom: 0;
  }

  .persona-main {
    display: flex;
  }

  .illustration-wrap {
    flex-basis: 15%;
    align-self: flex-end;

    @media (max-width: ${XS_BP}) {
      img {
        display: none;
      }
    }
  }
`;

const QuoteRoot = styled.div`
  position: relative;

  & > img {
    position: absolute;
    top: -30px;
  }

  & > p {
    color: ${(props) => props.theme.colors["text-accent-primary"]};
    font-size: 24px;
    font-weight: 700;
    font-style: italic;
    width: 80%;
    margin: 52px auto 57px auto;
  }

  @media (max-width: ${XS_BP}) {
    & > img {
      height: 73px;
      top: 12px;
    }
  }
`;

const Illustration = styled.figure`
  display: flex;
  margin: 0;
  gap: 24px;

  @media (max-width: ${XS_BP}) {
    flex-direction: column;
  }

  img {
    max-width: 100%;
  }

  & > figcaption {
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.colors["text-accented"]};

    & > h3 {
      margin-top: 0;
      margin-bottom: 7px;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
    }

    & > p {
      font-size: 16px;
      line-height: 28px;
    }
  }
`;

const MainText = styled.p`
  margin-top: 10px;
  font-size: 16px;
  line-height: 28px;
  color: ${(props) => props.theme.colors["text-accented"]};
`;

const TagLine = styled.p`
  font-size: 24px;
  font-weight: 700;
  align-self: flex-start;
  color: ${(props) => props.theme.colors["text-accented"]};
`;

export const MeetPersonaPage = () => (
  <Root>
    <Parallax
      speed={0}
      css={css`
        @media (max-width: ${XS_BP}) {
          display: none;
        }
      `}
    >
      <img
        src={ellipse}
        alt="background-pattern"
        css={css`
          right: 0;
          top: 0;
          position: absolute;
          z-index: 2;
        `}
      />
    </Parallax>
    <Parallax speed={0} className="content">
      <Content>
        <h2>Meet Michael</h2>
        <QuoteRoot>
          <img src={quoteMark} alt="quote-mark" />
          <p>
            Everybody has the ability to learn new things. The ability to show
            what they are capable of. Show it in different ways.
          </p>
        </QuoteRoot>
        <div className="persona-main">
          <div>
            <Illustration>
              <img src={bookImg} alt="book-illustration" />
              <figcaption>
                <h3>Michael’s Journey</h3>
                <p>
                  Michael is an 8th grader at Frasier school. He has always been
                  shy and introverted, he struggles to make friends and speak up
                  in class.
                </p>
              </figcaption>
            </Illustration>
            <MainText>
              Since Michael doesn't show a lot of his personality at school,
              it’s not easy for his teachers to build a connection with him and
              help him thrive at school. Michael also often struggles to
              understand what his talents are and how they could help him
              succeed.
            </MainText>
            <TagLine>
              That’s where <span className="accented">AYO</span> comes to
              the rescue.
            </TagLine>
          </div>
          <div className="illustration-wrap">
            <img src={michael} alt="illustration" />
          </div>
        </div>
      </Content>
    </Parallax>
  </Root>
);
