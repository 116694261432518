import styled from "@emotion/styled";
import { XS_BP } from "constants";

export const ContentRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 60vw;
  @media (max-width: ${XS_BP}) {
    max-width: 80vw;
  }

  header {
    h2 {
      font-weight: 700;
      font-size: 32px;
      color: ${(props) => props.theme.colors["text-accented"]};
      text-align: center;
    }
    p {
      text-align: center;
      font-size: 16px;
      color: ${(props) => props.theme.colors["text-main"]};
    }
  }

  form {
    .row {
      display: flex;
      gap: 28px;
    }
    .form-footer {
      margin-top: 16px;
    }
    .label {
      font-family: "Lato", sans-serif;
      font-size: 16px;
    }
    .submit {
      width: 100%;
      margin-top: 24px;
      margin-bottom: 60px;
    }
    .checkbox-error {
      padding-left: 30px;
      color: #C1292E;
      font-size: 10px;
      margin-bottom: 10px;
    }
    @media (max-width: ${XS_BP}) {
      .row {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
    }
  }
`;

export const Link = styled.a`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: ${(props) => props.theme.colors["primary@500"]};
  margin-right: 24px;
  white-space: nowrap;

  img {
    margin-right: 8px;
  }
`;

export const Footer = styled.footer`
  background: #f3f4f5;
  width: 100vw;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .footer-top-row {
    display: flex;
    justify-content: space-between;
    width: 80%;
    align-items: center;
    height: 120px;

    div:nth-of-type(2) {
      display: flex;
      align-items: center;
    }

    @media (max-width: ${XS_BP}) {
      flex-direction: column-reverse;
      justify-content: space-evenly;
    }
  }

  .footer-bottom-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 80px;
    border-top: 1px solid #e4e4e7;
    overflow: hidden;

    div:first-of-type {
      margin-left: 10%;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.75);
    }

    div:nth-of-type(2) {
      margin-right: 10%;
      display: flex;
    }
  }
`;
