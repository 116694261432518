import { useState } from "react";

import dataRaw from "../../elements/network-chart/data-raw";

export const colorMapping = {
  Aptitude: "#EFACD1",
  Course: "#778DBA",
  Volunteering: "#BD7C7A",
  Activity: "#df6b79",
  Interest: "#6089d4",
  Extracurricular: "#DEC89B",
  Student: "#A0CF8C",
};

export const useGraphData = (url) => {
  const [graph, setGraph] = useState({ nodes: null, edges: null });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const processResponse = (response) => {
    setGraph({
      ...response,
      aptitudes: response.aptitudes.map(x => x.aptitude).sort((a, b) => a.length - b.length),
      interests: response.interests.map((x) => x.interest.split("/").pop()).sort((a, b) => a.length - b.length),
      nodes: response.graph.nodes.map((x) => ({
        ...x,
        color: colorMapping[x.type]
      })),
      edges: response.graph.adjacency.reduce((acc, current, i) => {
        acc.push(
          ...current.map((item) => ({
            target: response.graph.nodes[i].id,
            source: item.id,
            label: item.type
          }))
        );
        return acc;
      }, []),
    });
    setIsLoading(false);
  };

  const getMock = () => {
    return Promise.resolve(dataRaw).then(processResponse);
  };

  const fetchData = (payload) => {
    setError(null);
    setIsLoading(true);
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        setIsLoading(false);
        setError("Can\'t load the data, try submitting the form again.");
        return false;
      })
      .then(processResponse)
      .catch((e) => {
        setError("Can\'t process the data, try again.");
        return false;
      })
      .then(() => {
        setIsLoading(false);
        return true;
      });
  };

  return {
    fetchData,
    getMock,
    graph,
    isLoading,
    error,
  };
};
