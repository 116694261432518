import React from "react";
import Tilt from "react-parallax-tilt";

import { Block } from "../../elements/block/Block";

export const InfoBlock = ({ children, style, withTilt, innerStyle, id }) => (
  <Tilt
    tiltEnable={withTilt}
    tiltMaxAngleX={9}
    tiltMaxAngleY={9}
    style={{ flexGrow: 1, ...style }}
  >
    <Block style={innerStyle} id={id}>
      {children}
    </Block>
  </Tilt>
);
