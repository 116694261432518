import React, { useRef, useState, useCallback } from "react";

import { ShepherdTour } from "react-shepherd";

import { Header } from "elements/header/Header";
import { GetToKnowPage } from "page-layers/1-get-to-know/GetToKnowPage";
import { WhyPage } from "page-layers/2-why-ayo/WhyPage";
import { OpportunityPage } from "page-layers/3-opportunity/OpportunityPage";
import { HowItWorksPage } from "page-layers/4-how-it-works/HowItWorksPage";
import { MeetPersonaPage } from "page-layers/5-meet-persona/MeetPersonaPage";
import { DemoPage } from "page-layers/6-demo/DemoPage";
import { ProfilePage } from "page-layers/6-profile/ProfilePage";
import { ValueToPage } from "page-layers/7-value/ValueToPage";
import { RequestDemoPage } from "page-layers/8-request-demo/RequestDemoPage";

import steps from "tour-steps";

const tourOptions = { useModalOverlay: true };

export const Entry = () => {
  const [isHeaderHidden, setIsHeaderHidden] = useState(false);
  const demoRef = useRef();

  const handleTourClose = useCallback(() => setIsHeaderHidden(false), []);
  const handleTourOpen = useCallback(() => setIsHeaderHidden(true), []);

  return (
    <>
      <Header demoRef={demoRef} isInvisible={isHeaderHidden} />
      <GetToKnowPage demoRef={demoRef} />
      <WhyPage />
      <OpportunityPage />
      <HowItWorksPage />
      <MeetPersonaPage />
      <ProfilePage />
      <ShepherdTour tourOptions={tourOptions} steps={steps}>
        <DemoPage
            onTourClose={handleTourClose}
            onTourOpen={handleTourOpen}
        />
      </ShepherdTour>
      <ValueToPage />
      <RequestDemoPage ref={demoRef} />
    </>
  );
};
