/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import { css } from "@emotion/react";
import { useId } from "@mantine/hooks";
import { drawD3Chart } from "./draw-d3-chart";

export const NetworkInternal = ({
  graph,
  width,
  height,
  withLabels,
  isZoomable,
  simulationOptions
}, ref) => {
  const uuid = useId();
  const d3root = useRef();
  const chartHandle = useRef();

  useEffect(() => {
    chartHandle.current = drawD3Chart({
      width,
      height,
      el: d3root,
      id: uuid,
      withLabels,
      isZoomable,
      simulationOptions,
      graph
    });
  }, [
    graph,
    width,
    height,
    d3root,
    uuid,
    withLabels,
    isZoomable,
    simulationOptions
  ]);

  useImperativeHandle(ref, () => ({
    zoomIn: () => chartHandle.current.zoomIn(),
    zoomOut: () => chartHandle.current.zoomOut(),
  }));

  return (
    <div
      css={css`
        cursor: grab;
      `}
      ref={d3root}
    />
  );
};

export const Network = forwardRef(NetworkInternal);
