import React from "react";

import styled from "@emotion/styled";

const Figure = styled.figure`
  display: flex;
  margin: 0;
  gap: 24px;
  color: #fff;
  & img {
    width: 80px;
  }
`;

const Legend = styled.figcaption`
  @media (max-width: 420px) {
    & h3 {
      margin-bottom: 2px;
    }
    & p {
      margin-top: 3px;
    }
  }
  & h3 {
    font-size: 24px;
  }
  & p {
    font-size: 16px;
    line-height: 28px;
  }
`;

export const Paragraph = ({ icon, headline, text }) => (
  <Figure>
    <img src={icon} alt={`${headline}-icon`} />
    <Legend>
      <h3>{headline}</h3>
      <p>{text}</p>
    </Legend>
  </Figure>
);
