import styled from '@emotion/styled'

export const Block = styled.article`
  box-sizing: border-box;
  border-radius: 16px;
  padding: 14px 16px;
  background: #fff;
  box-shadow: 0 12px 16px 6px rgba(0, 0, 0, 0.06);
  
  h4 {
    margin: 0;
  }
`;
