/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import Parallax from "react-rellax";
import styled from "@emotion/styled";

import profile from "images/profile.png";
import ellipseWhite from "svg/background-parallax-layers/ellipse-white.svg";
import ellipsePurple from "svg/background-parallax-layers/ellipse-purple.svg";
import ellipseStroke from "svg/background-parallax-layers/ellipse-stroke.svg";
import polkaDot from "svg/background-parallax-layers/dotted-pattern.svg";

import { XS_BP } from "constants";

const Root = styled.section`
  background: #f2f3fe;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Content = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;

  h2 {
    margin-top: 34px;
    margin-bottom: 24px;
    font-size: 38px;
    color: ${(props) => props.theme.colors["text-main"]};
  }

  p {
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #404040;
    width: 85%;
  }

  .profile-image {
    width: 80%;
    img {
      max-width: 100%;
    }
  }

  @media (max-width: ${XS_BP}) {
    width: 100vw;

    p {
      width: 90%;
    }
  }
`;

export const ProfilePage = () => (
  <Root>
    <Parallax
      speed={1.1}
      css={css`
        position: absolute;
        top: 600px;
        left: 50vw;
        z-index: 0;
        @media (max-width: ${XS_BP}) {
          display: none;
        }
      `}
    >
      <img src={ellipseWhite} alt="background-layer" />
    </Parallax>
    <Parallax
      speed={1.5}
      css={css`
        position: absolute;
        top: 850px;
        left: 170px;
        z-index: 1;
        @media (max-width: ${XS_BP}) {
          display: none;
        }
      `}
    >
      <img src={ellipsePurple} alt="background-layer" />
    </Parallax>
    <Parallax
      speed={1.25}
      css={css`
        position: absolute;
        top: 650px;
        left: 70px;
        z-index: 0;
        @media (max-width: ${XS_BP}) {
          display: none;
        }
      `}
    >
      <img src={ellipseStroke} alt="background-layer" />
    </Parallax>
    <Parallax
      speed={1.25}
      css={css`
        position: absolute;
        bottom: 20px;
        left: 20px;
        z-index: 0;
        @media (max-width: ${XS_BP}) {
          display: none;
        }
      `}
    >
      <img src={polkaDot} alt="background-layer" />
    </Parallax>
    <Content>
      <h2>Michael's profile</h2>
      <p css={css`margin-bottom: 8px;`}>
        <span className="accented">AYO</span> has been gathering and aggregating
        a lot of information about Michael since his first day at Mesquite ISD.
        All of the analyzed data is transformed into Michael’s Profile, where
        Michael himself, his teachers, and his parents can see his Achievements,
        Extracurriculars, Courses, Interests, Leadership Attributes and even
        Potential Targets in one place.
      </p>
      <p css={css`margin-top: 0;margin-bottom: 19px;`}>
        This <span className="accented">AYO</span> Profile is a concept, the actual
        look and feel of a student profile can be customized to fit your
        district's needs.
      </p>
      <div className="profile-image">
        <img src={profile} alt="profile-screen" />
      </div>
    </Content>
  </Root>
);
