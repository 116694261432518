import React from "react";

import styled from "@emotion/styled";

export const IconSkeleton = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 16px;
  background: #6066A3;
  opacity: 0.2;
`;
