/** @jsxImportSource @emotion/react */
import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { InfoBlock } from "../info-block/InfoBlock";
import diamond from "../../svg/icons/diamond.svg";
import star from "../../svg/icons/star.svg";
import { TextSkeleton } from "../text-skeleton/TextSkeleton";
import { Bar } from "../bar/Bar";

const SkeletonGrid = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;

  &:first-of-type {
    flex-basis: 25%;
  }
`;

const IconRow = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
`;

const gridLayout = [
  ["56", "77", "100"],
  ["100", "100", "100"],
  ["100", "100", "100"],
  ["100", "100", "100"],
];

export const TestResults = () => (
  <InfoBlock withTilt>
    <h4>Interests & Aptitudes</h4>
    <SkeletonGrid>
      {gridLayout.map((row, i) => (
        <div key={i} css={css`flex-basis: 25%;`}>
          {row.map((block, j) => (
            <TextSkeleton key={`${j}-${block}`} value={block} />
          ))}
        </div>
      ))}
    </SkeletonGrid>
    <div>
      <IconRow>
        <div />
        <img src={diamond} className="" alt="icon-diamond" />
        <img src={star} className="" alt="icon-star" />
      </IconRow>
      <div>
        <Bar color="purple" value="60%" />
        <TextSkeleton isFullWidth />
      </div>
    </div>
  </InfoBlock>
);
