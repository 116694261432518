import studentsValue from "../svg/illustrations/7-value/students-value.svg";
import educatorsValue from "../svg/illustrations/7-value/educators-value.svg";
import parentsValue from "../svg/illustrations/7-value/parents-value.svg";

export default {
  students: {
    key: "students",
    image: studentsValue,
    points: [
      <span>
        <span className="accented">AYO</span> goes beyond traditional metrics,
        allowing students to make more informed decisions about their future
      </span>,
      <span>
        <span className="accented">AYO</span> increases the likelihood of
        students taking agency over their learning
      </span>,
      <span>
        <span className="accented">AYO</span> grows alongside the student,
        capturing the changes and keeping track of aptitudes and interests from
        the first day of school
      </span>,
      <span>
        <span className="accented">AYO</span> helps students to discover their
        passions and take control of their educational journey
      </span>,
      <span>
        <span className="accented">AYO</span> exposes students to new interests
        and ideas
      </span>,
      <span>
        <span className="accented">AYO</span> visualizes student growth,
        aptitudes, and leadership
      </span>,
    ],
  },
  educators: {
    key: "educators",
    image: educatorsValue,
    points: [
      <span>
        <span className="accented">AYO</span> aggregates the information
        teachers need to tailor the learning experience for their students
      </span>,
      <span>
        The smarts of <span className="accented">AYO</span> enables teachers to
        take action with valuable insights, without{" "}
        <span className="accented">AYO</span> this level of tailored learning
        would not be possible
      </span>,
      <span>
        Teachers get the bigger picture of each student - not just what they
        observe in class, but the information on what students enjoy, and how they
        learn best
      </span>,
      <span>
        Teachers get an opportunity to see how each student is doing on a bigger
        scale - not only in their class, hence will be able to get together with
        other teachers to help the students tackle issues they are struggling
        with
      </span>,
    ],
  },
  parents: {
    key: "parents",
    image: parentsValue,
    points: [
      <span>
        Parents are able to see a complex picture of each of their children, so
        much more than just the grades
      </span>,
      <span>
        Understanding their children's interests and passions, parents are able
        to reinforce those at home, planning family activities around those
        interests
      </span>,
      <span>
        <span className="accented">AYO</span> exposes parents to new interests
        for their children
      </span>,
      <span>
        Parents have insights about Leadership Attributes their children are
        working on, and are able to assist with their children's progression at
        home
      </span>,
      <span>
        Providing input themselves, parents help{" "}
        <span className="accented">AYO</span> create a relevant student story
      </span>,
    ],
  },
};
