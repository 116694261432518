/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Parallax from "react-rellax";
import { useMediaQuery, useViewportSize } from "@mantine/hooks";

import { SmartProfile } from "elements/smart-profile/SmartProfile";
import { SmartPath } from "elements/smart-path/SmartPath";
import { AptitudesMap } from "elements/aptitudes-map/AptitudesMap";
import { RoadMapCorrelation } from "elements/road-map-correlation/RoadMapCorrelation";
import { TestResults } from "elements/test-results/TestResults";
import { Button } from "elements/button/Button";

import bubbles from "svg/background-parallax-layers/bubbles.svg";
import waves from "svg/background-parallax-layers/waves.svg";
import bubbles2 from "svg/background-parallax-layers/bubbles-2.svg";
import { scrollWithOffset } from "utils/dom-utils";
import { SM_BP, XL_BP, XS_BP } from "constants";

const Root = styled.section`
  background-color: #fff;
  z-index: 1;
  height: 85vh;
  position: relative;
`;

const ContentRoot = styled.div`
  width: 85vw;
  @media (min-width: ${SM_BP}) {
    width: 65vw;
  }
  @media (min-width: ${XL_BP}) {
    width: 75vw;
  }
`;

const Content = styled.div`
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${XL_BP}) {
    padding-top: 130px;
  }
`;

export const Jumbotron = styled.div`
  width: 63%;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${(props) => props.theme.colors["text-main"]};

  & h2 {
    font-size: 38px;
    text-align: center;
    @media (max-width: ${SM_BP}) {
      margin-top: 48px;
    }
  }
  & p {
    margin: 0;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    z-index: 3;
    @media (max-width: ${SM_BP}) {
      margin-top: 16px;
    }
  }
  & .cta-block {
    @media (min-width: ${SM_BP}) {
      & > button {
        width: 300px;
      }
    }
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 24px;
  }

  @media (max-width: ${SM_BP}) {
    width: 90%;
  }
`;

const CardsShowcaseGrid = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${XL_BP}) {
    margin-top: 80px;
  }

  .row {
    display: flex;
    gap: 16px;
  }

  .row:nth-of-type(1) > div {
    flex-basis: 33%;
  }

  .row:nth-of-type(2) > div {
    flex-basis: 50%;
  }

  @media (max-width: ${SM_BP}) {
    margin-top: 30px;
    & > div:nth-of-type(2) {
      margin-top: 30px;
    }
  }

  .row:nth-of-type(1) > div:nth-of-type(2) {
    margin-top: 80px;
    z-index: 2;
  }
  .row:nth-of-type(1) > div:nth-of-type(3) {
    margin-top: 40px;
  }
  .row:nth-of-type(2) > div:nth-of-type(1) {
    margin-top: -80px;
    height: 204px;
    flex-grow: 1;
    @media (min-width: ${XL_BP}) {
      margin-top: -30px;
    }
  }
  .row:nth-of-type(2) > div:nth-of-type(2) {
    flex-grow: 1;
    height: 204px;
    @media (min-width: ${XL_BP}) {
      margin-top: 20px;
    }
  }
`;

export const GetToKnowPage = ({ demoRef }) => {
  const isFullLayout = useMediaQuery(`(min-width: ${SM_BP})`);
  const { height } = useViewportSize();
  return (
    <Root>
      <Parallax
        speed={2.7}
        css={css`
          top: 200px;
          ${isFullLayout ? "left: 250px" : "right: 10px"};
          z-index: -1;
          position: absolute;
          @media (max-width: ${XS_BP}) {
            display: none;
          }
        `}
      >
        <img src={bubbles} alt="background-layer-icon-bubbles" />
      </Parallax>
      <Parallax
        speed={2}
        css={css`
          top: 350px;
          left: ${isFullLayout ? "100px" : "-20px"};
          z-index: -1;
          position: absolute;
          @media (max-width: ${XS_BP}) {
            display: none;
          }
        `}
      >
        <img src={waves} alt="background-layer-icon-waves" />
      </Parallax>
      <Parallax
        speed={1.5}
        css={css`
          top: 50px;
          right: 0;
          z-index: -2;
          position: absolute;
          @media (max-width: ${XS_BP}) {
            display: none;
          }
        `}
      >
        <img src={bubbles2} alt="background-layer-icon-bubbles" />
      </Parallax>
      <Parallax
        speed={isFullLayout ? 7 : 3}
        className="content"
        css={css`
          z-index: 3;
        `}
      >
        <ContentRoot>
          <Content>
            <Jumbotron>
              <h2>
                Get to know <span className="accented">AYO</span>
              </h2>
              <p>
                <span className="accented">AYO</span> leverages the power of AI
                to enable the next generation of education by providing insights
                tailored to each student
              </p>
              <div className="cta-block">
                <Button
                  onClick={() => {
                    scrollWithOffset(demoRef.current);
                  }}
                >
                  Request a Demo
                </Button>
              </div>
            </Jumbotron>
            {isFullLayout ? (
              <CardsShowcaseGrid isFullLayout>
                <div className="row">
                  <SmartProfile />
                  <SmartPath />
                  <AptitudesMap />
                </div>
                <div className="row">
                  <RoadMapCorrelation />
                  <TestResults />
                </div>
              </CardsShowcaseGrid>
            ) : (
              <CardsShowcaseGrid isFullLayout={false}>
                <SmartProfile />
                <SmartPath />
              </CardsShowcaseGrid>
            )}
          </Content>
        </ContentRoot>
      </Parallax>
    </Root>
  );
};
