import React from "react";
import styled from "@emotion/styled";

export const Button = styled.button`
  pointer-events: ${props => props.isLoading ? "none" : "all"};
  opacity: ${props => props.isLoading ? 0.5 : 1};
  cursor: pointer;
  box-sizing: border-box;
  padding: 12px 15px;
  background: ${(props) => props.theme.colors["primary@500"]};
  color: #fff;
  font-weight: 700;
  font-size: ${(props) => `${props.theme.typography.sizing.button}px`};
  font-family: "Lato", sans-serif;
  line-height: 25px;
  text-align: center;
  border: 1px solid ${(props) => props.theme.colors["primary@500"]};
  border-radius: ${(props) => `${props.theme.radius}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`;
