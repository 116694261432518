import React from "react";
import { TextInput as MantineTextInput } from "@mantine/core";

import { useTheme } from '@emotion/react'

export const TextInput = ({ label, style, styles, ...rest }) => {
  const theme = useTheme();
  return (
    <MantineTextInput
      label={label}
      styles={{
        input: {
          borderRadius: `${theme.radius}px`,
          border: "1px solid #E4E4E7",
          color: theme.colors["text-main"],
          height: 40,
        },
        label: {
          color: theme.colors["text-accent-primary"],
          transform: "translateY(13px) translateX(12px)",
          zIndex: 3,
          fontWeight: 300,
          fontSize: 12,
        },
        ...styles,
      }}
      style={{ ...style }}
      {...rest}
    />
  );
};
