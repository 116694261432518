import React from "react";
import { OverflowList } from "react-overflow-list";
import { Tooltip, Menu } from "@mantine/core";

import { Tag } from "../tag/Tag";

export const TagContainer = ({
   items,
   style,
   onItemClick,
   onMoreClick,
   itemWidth,
   moreLabelFormatter = (x) => `+${x.length}`,
   ...props
 }) => {
  return (
    <OverflowList
      collapseFrom="end"
      minVisibleItems={1}
      itemWidth={120}
      style={style}
      items={items || []}
      itemRenderer={(item) => {
        if (item.target) {
          return (
            <Tooltip
              key={`${item.target} - ${item.target_category}`}
              styles={{
                body: {
                  padding: 12,
                  background: "#303359",
                  borderRadius: "16px",
                  fontSize: 10,
                  color: "#fff",
                  width: 130
                }
              }}
              label={`${item.target} - ${item.target_category}`}
            >
              <Tag
                width={itemWidth}
                title={`${item.target} - ${item.target_category}`}
                key={item.target}
                onClick={onItemClick}
                isLarge
              >
                {item.target} - {item.target_category}
              </Tag>
            </Tooltip>
          );
        }
        return (
          <Tooltip
            key={item}
            styles={{
              body: {
                padding: 12,
                background: "#303359",
                borderRadius: "16px",
                fontSize: 10,
                color: "#fff",
                width: 130
              }
            }}
            label={item}
          >
            <Tag onClick={onItemClick} title={item} width={itemWidth}>
              {item}
            </Tag>
          </Tooltip>
        );
      }}
      overflowRenderer={(items) => {
        return (
          <Menu
            zIndex={9999}
            control={<Tag themeColor="#6066A3">{moreLabelFormatter(items)}</Tag>}
            styles={{
              body: {
                maxHeight: 200,
                overflowY: "scroll"
              },
              item: {
                textTransform: "capitalize",
                fontFamily: "Lato",
                fontSize: 14,
                padding: "8px 16px",
                borderRadius: 16,
                color: "#404040"
              }
            }}
          >
            {items.map((item) => (
              <Menu.Item key={item.target ? item.target : item}>
                {item.target ? `${item.target} (${item.target_category})` : item}
              </Menu.Item>
            ))}
          </Menu>
        );
      }}
      maxCount="responsive"
      {...props}
    />
  );
}
