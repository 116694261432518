import React from "react";

import styled from "@emotion/styled"

export const TextSkeleton = styled.p`
  height: 8px;
  background: #6066a3;
  opacity: 0.2;
  border-radius: 32px;
  margin-bottom: 12px;
  width: ${props => props.isFullWidth ? "100%" : `${props.value}%`};
`;
