/** @jsxImportSource @emotion/react */
import React, { forwardRef } from "react";
import Parallax from "react-rellax";
import { Checkbox } from "@mantine/core";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Modal } from "@mantine/core";

import { TextInput } from "elements/forms/text-input/TextInput";
import { Button } from "elements/button/Button";

import softserve from "svg/logos/softserve.svg";
import loadingIcon from "svg/icons/loading-icon.svg";
import formSuccessImage from "svg/illustrations/8-request-demo/form-success.svg";
import formErrorImage from "svg/illustrations/8-request-demo/form-error.svg";

import { Content, ContentRoot, Footer, Link } from "./styles";
import { useRequestDemoForm } from "features/forms/useRequestDemoForm";
import { XS_BP } from "constants";

const Root = styled.section`
  background-color: #fff;
  z-index: 1;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const footerLinks = [
  {
    url: "https://myayo.world",
    label: "Myayo.world",
  },
  {
    url: "https://www.softserveinc.com/en-us/privacy-policy",
    label: "Privacy Policy",
  },
];

const formLayout = [
  [
    {
      label: "First Name *",
      id: "firstName",
    },
    {
      label: "Last Name *",
      id: "lastName",
    },
  ],
  [
    {
      label: "Email *",
      id: "email",
    },
    {
      label: "Phone",
      id: "phone",
    },
  ],
  [
    {
      label: "Company",
      id: "company",
    },
    {
      label: "Job Title",
      id: "jobTitle",
    },
  ],
];

export const RequestDemoPage = forwardRef((_, ref) => {
  const { sendData, isSending, isSuccess, opened, handlers, form } =
    useRequestDemoForm();

  const sendRequestDemoForm = (e) => {
    e.preventDefault();
    if (!form.validate().hasErrors) {
      sendData().then(() => handlers.open());
    }
  };

  return (
    <Root ref={ref}>
      <Parallax speed={0}>
        <ContentRoot>
          <Content>
            <header>
              <h2>Request a demo</h2>
              <p>
                Ready to see what <span className="accented">AYO</span> can do
                for your district? Request a demo and we will be in touch with
                you shortly.
              </p>
            </header>
            <form>
              {formLayout.map((row, i) => (
                <div className="row" key={i}>
                  {row.map((input, j) => (
                    <TextInput
                      className="request-demo-input"
                      key={j}
                      css={css`
                        width: 50%;
                        margin-bottom: 16px;
                        ${form.errors[input.id]
                          ? "label { color: #C1292E; }"
                          : ""};
                        @media (max-width: ${XS_BP}) {
                          width: 100%;
                          margin-bottom: 0;
                        }
                      `}
                      label={input.label}
                      {...form.getInputProps(input.id)}
                    />
                  ))}
                </div>
              ))}
              <div className="form-footer">
                <Checkbox
                  css={css`
                    margin-bottom: ${form.errors.termsAccepted ? 5 : 20};
                  `}
                  label={
                    <span className="label">
                      I have read and accepted the{" "}
                      <a
                        href="https://www.softserveinc.com/en-us/privacy-policy"
                        target="_blank"
                        rel="noopener"
                      >
                        Privacy Policy
                      </a>
                      .
                    </span>
                  }
                  {...form.getInputProps("termsAccepted", {
                    type: "checkbox",
                  })}
                />
                <div className="checkbox-error">
                  {form.errors.termsAccepted}
                </div>
                <Checkbox
                  label={
                    <span className="label">
                      I would like to be updated on the latest products, event
                      announcements, and thought leadership.
                    </span>
                  }
                  {...form.getInputProps("subscribedForUpdates", {
                    type: "checkbox",
                  })}
                />
                <Button
                  className="submit"
                  isLoading={isSending}
                  onClick={sendRequestDemoForm}
                >
                  {isSending && (
                    <img
                      className="rotation"
                      css={css`
                        margin-right: 8px;
                      `}
                      src={loadingIcon}
                      alt="is-loading-spinner-icon"
                    />
                  )}
                  Request a Demo
                </Button>
              </div>
            </form>
          </Content>
          <Footer>
            <div className="footer-top-row">
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 7px;
                  font-size: 12px;
                  line-height: 24px;
                  color: rgba(0, 0, 0, 0.75);
                `}
              >
                © Copyright 2022
                <Link
                  css={css`
                    margin-right: 0;
                  `}
                  href="https://www.softserveinc.com/en-us"
                  target="_blank"
                  rel="noopener"
                >
                  <img src={softserve} alt="softserve-logo" />
                </Link>
              </div>
              <div>
                {footerLinks.map((link, i) => (
                  <Link
                    key={link.label}
                    css={css`
                      margin-right: ${i === footerLinks.length - 1 ? 0 : 24};
                    `}
                    href={link.url}
                    target="_blank"
                    rel="noopener"
                  >
                    {link.label}
                  </Link>
                ))}
              </div>
            </div>
          </Footer>
        </ContentRoot>
      </Parallax>
      <Modal
        overlayColor="#000"
        overlayOpacity={0.5}
        centered
        opened={opened}
        onClose={() => handlers.close()}
        radius={16}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
          `}
        >
          {isSuccess ? (
            <>
              <img src={formSuccessImage} alt="" />
              <p
                css={css`
                  font-size: 22px;
                  color: #000;
                  font-weight: 700;
                  margin-bottom: 8px;
                  margin-top: 24px;
                `}
              >
                Thank you for your message!
              </p>
              <p
                css={css`
                  font-size: 16px;
                  line-height: 28px;
                  color: #404040;
                `}
              >
                We have received the demo request and will contact you in the
                nearest future.
              </p>
              <Button
                css={css`
                  align-self: flex-start;
                  width: 50%;
                `}
                onClick={() => {
                  handlers.close();
                }}
              >
                Close
              </Button>
            </>
          ) : (
            <>
              <img src={formErrorImage} alt="" />
              <p
                css={css`
                  font-size: 22px;
                  color: #000;
                  font-weight: 700;
                  margin-bottom: 8px;
                  margin-top: 24px;
                `}
              >
                Oops... Something went wrong
              </p>
              <p
                css={css`
                  font-size: 16px;
                  line-height: 28px;
                  color: #404040;
                `}
              >
                We couldn't send your message. Please try again.
              </p>
              <Button
                css={css`
                  align-self: flex-start;
                  width: 50%;
                `}
                onClick={() => {
                  form.reset();
                  handlers.close();
                }}
              >
                Retry
              </Button>
            </>
          )}
        </div>
      </Modal>
    </Root>
  );
});
