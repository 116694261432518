/** @jsxImportSource @emotion/react */
import React, { useState, useMemo } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Slider } from "@mantine/core";
import { Tooltip } from "@mantine/core";

import background from "../../svg/card-background.svg";
import { getSliderStyles } from "./styles";

const marks = [
  { value: 0, label: "Not interested" },
  { value: 33, label: "Neutral" },
  { value: 66, label: "Like it" },
  { value: 99, label: "Love it!" },
];

const interestValueToKey = {
  0: "not interested",
  33: "neutral",
  66: "like it",
  99: "love it",
};

const CardRoot = styled.div`
  color: ${(props) => props.theme.colors["text-main"]};
  height: 92px;
  flex-basis: ${(props) => (props.isSliderView ? "100%" : "48%")};

  background: #fff;
  border-radius: ${(props) => props.theme.radius}px;
  margin: 0 0 4px 0;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0 12px 16px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  position: relative;

  .card-background {
    position: absolute;
    right: 0;
    height: 92px;
    top: 0;
    z-index: 0;
  }

  .card-view {
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    transform: ${(props) =>
      props.isSliderView ? "translate(-120%, 0%)" : "translateX(0%, 0%)"};
    transition: all 0.2s ease;

    figure {
      z-index: 1;
      display: flex;
      gap: 70px;
      align-items: center;
      margin: 0;
      width: 100%;
    }

    figcaption {
      flex-basis: 70%;
      overflow: hidden;

      & h3 {
        white-space: nowrap;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 40px;
        div {
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
      }

      div {
        font-size: 12px;
      }

      span {
        color: ${(props) => props.theme.colors["text-accent-primary"]};
      }
    }

    figcaption + div {
      position: absolute;
      right: 17px;

      font-size: 34px;
    }
  }

  .slider-view {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: ${(props) =>
      props.isSliderView ? "translate(0%, -100%)" : "translate(120%, -100%);"};

    h3 {
      font-size: 10px;
      color: ${(props) => props.theme.colors["text-main"]};
      font-weight: 700;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: -28px;
    }
  }

  // overrides
  .mantine-Slider-bar {
    background: linear-gradient(
        180deg,
        rgba(142, 48, 120, 0.65) 0%,
        rgba(0, 0, 0, 0.15) 100%
      ),
      linear-gradient(180deg, #6065a8 0%, rgba(92, 67, 161, 0.84) 100%);
    border-radius: 21px;
  }
`;

export const InterestCard = ({
  interest,
  setInterestValues,
  interestValues,
  onRemove,
}) => {
  const [isSliderView, setIsSliderView] = useState(
    () => !interestValues[interest] && interestValues[interest] !== 0
  );
  const [value, setValue] = useState(0);
  const [isFinal, setIsFinal] = useState(false);

  const thumbIcon = useMemo(() => {
    if (value === 0) {
      return "😐";
    }
    if (value === 33) {
      return "🤔";
    }
    if (value === 66) {
      return "🤩";
    }
    return "🥰";
  }, [value]);

  const displayValue = useMemo(() => interest.split("/").pop(), [interest]);

  const reactionName = useMemo(() => {
    return marks.find((x) => x.value === value).label;
  }, [value]);

  const onChangeEnd = (x) => {
    setInterestValues((p) => ({ ...p, [interest]: x }));
    setIsSliderView(false);
    setIsFinal(true);
  };

  return (
    <CardRoot isSliderView={isSliderView}>
      {!isFinal && (
        <div
          onClick={() => onRemove(interest)}
          css={css`
            position: absolute;
            top: 5px;
            right: 15px;
            color: #000;
            z-index: 5;
            cursor: pointer;
          `}
        >
          &times;
        </div>
      )}
      <img className="card-background" src={background} alt="background" />
      <div className="card-view">
        <figure className="figure">
          <figcaption className="figcaption">
            <h3>
              <Tooltip
                position="bottom-start"
                styles={{
                  body: {
                    padding: 12,
                    background: "#303359",
                    borderRadius: "16px",
                    fontSize: 10,
                    color: "#fff",
                    width: 130,
                  },
                }}
                label={displayValue}
              >
                {displayValue}
              </Tooltip>
            </h3>
            <div>
              Rate: <span>{reactionName}</span>
            </div>
          </figcaption>
          <div>{thumbIcon}</div>
        </figure>
      </div>
      {!isFinal && (
        <div className="slider-view">
          <h3>Pick an Interest Reaction for {displayValue}</h3>
          <Slider
            value={value}
            onChange={setValue}
            label={() => null}
            thumbChildren={thumbIcon}
            onChangeEnd={onChangeEnd}
            step={33}
            marks={marks}
            styles={getSliderStyles}
          />
        </div>
      )}
    </CardRoot>
  );
};
