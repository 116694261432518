/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { formList, useForm } from "@mantine/form";
import { Accordion, MultiSelect, Select, Textarea } from "@mantine/core";
import { CircularLoading } from "respinner";

import testData from "data/test-data";
import interestList from "data/interests";

import { TextInput } from "elements/forms/text-input/TextInput";
import { NumberInput } from "elements/forms/number-input/NumberInput";
import { InterestCard } from "elements/interest-card/InterestCard";
import { GhostButton } from "elements/button/GhostButton";
import { Button } from "elements/button/Button";
import { MultiselectInputPill } from "elements/forms/multiselect-input-pill/MultiselectInputPill";

import bin from "svg/icons/bin.svg";
import arrowDown from "svg/icons/arrow-down.svg";


import { useFormPreprocessor } from "./useFormPreprocessor";

const Root = styled.div`
  .note {
    margin: 6px 0;
    color: #404040;
    font-size: 10px;
    text-align: right;
    line-height: 16px;

    &.pull-left {
      text-align: left;
    }
  }
  .accordion-label-header {
    font-family: "Lato", sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: #404040;
  }

  .row {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
  }
  
  .error-block {
    color: #C1292E;
    font-size: 12px;
    margin-top: 8px;
    padding-left: 5px;
  }
`;

export const StudentForm = ({ isBusy, onSubmit, error }) => {
  const [interestValues, setInterestValues] = useState({});
  const form = useForm({
    initialValues: {
      ...testData,
      test_scores: formList(testData.test_scores),
      google_search_queries: formList(testData.google_search_queries),
      user_selected_interests: formList([]),
      essays: formList(testData.essays),
    },
    validate: {
      test_scores: {
        test_title: (x) =>
          !(x === "Math" || x === "Reading" || x === "English")
            ? "no subject selected"
            : null,
        score: (x) => (!(x > 0) ? "wrong score" : null),
      },
      google_search_queries: {
        query: x => x.trim().length > 1500 ? "The query you provided exceeds the 1500-characters limit" : null
      },
      essays: {
        text: (val) =>
          val.split(" ").filter((x) => x).length > 500
            ? "more than 500 words"
            : null,
      },
    },
  });
  const { value } = useFormPreprocessor(form.values, interestValues);

  // hack to sync in state.interestValues and forms.user_selected_interests
  useEffect(() => {
    setInterestValues((p) => {
      return form.values.user_selected_interests
        .filter((key) => key in p)
        .reduce((obj2, key) => ((obj2[key] = p[key]), obj2), {});
    });
  }, [form.values.user_selected_interests]);

  useEffect(() => {
    onSubmit(value);
  }, []);

  const onProcessData = () => {
    onSubmit(value);
  };

  return (
    <Root>
      <Accordion
        initialItem={0}
        styles={{
          control: {
            "&:hover": {
              backgroundColor: "transparent",
              opacity: 1,
            },
          },
          item: {
            borderBottom: "1px solid #E4E4E7",
          },
          content: {
            padding: 8,
          },
          contentInner: {
            padding: "8px 0",
          },
        }}
      >
        <Accordion.Item
          label={
            <header className="accordion-label-header">
              <span id="student-info">Student Info</span>
            </header>
          }
        >
          <div className="row">
            <TextInput
              label="First Name"
              css={css`
                flex-basis: 50%;
              `}
              {...form.getInputProps("student_name")}
              disabled
            />
            <NumberInput
              label="Grade"
              containerStyle={{ flexBasis: "50%" }}
              {...form.getInputProps("student_grade")}
              min={0}
              max={100}
            />
          </div>
          <MultiSelect
            sx={() => ({
              fontFamily: "Lato",
              "&:hover": {
                backgroundColor: "transparent",
              },
            })}
            rightSection={<img src={arrowDown} alt="icon-select-expand"/>}
            styles={{
              rightSection: { pointerEvents: "none" },
              item: {
                color: "rgba(0, 0, 0, 0.75)",
                fontSize: 16,
                fontWeight: 400,
                borderRadius: 16,
                "&:hover": {
                  background: "#F2F3FF",
                  color: "#6066A3",
                  fontWeight: 700,
                },
              },
              label: {
                color: "#6066A3",
                transform: "translateY(13px) translateX(12px)",
                zIndex: 3,
                fontWeight: 300,
                fontSize: 12,
              },
            }}
            label="Student Interests"
            radius={16}
            searchable
            onChange={(val) => {
              form.setFieldValue("user_selected_interests", val);
            }}
            valueComponent={({ value, onRemove }) => (
              <MultiselectInputPill
                value={value}
                onRemove={onRemove}
                background={
                  interestValues[value] || interestValues[value] === 0
                    ? "#F2F3FF"
                    : "#6066A3"
                }
                color={
                  interestValues[value] || interestValues[value] === 0
                    ? "#6066A3"
                    : "#F2F3FE"
                }
              />
            )}
            value={form.values.user_selected_interests}
            data={interestList.interests.split(",")}
          />
          <p className="note">
            Fill the container with your liked or disliked interests
          </p>
          <div
            css={css`
              display: flex;
              gap: 8px;
              flex-wrap: wrap;
            `}
          >
            {form.values.user_selected_interests.map((interest) => (
              <InterestCard
                interest={interest}
                onRemove={(interest) => {
                  form.setFieldValue(
                    "user_selected_interests",
                    form.values.user_selected_interests.filter(
                      (x) => x !== interest
                    )
                  );
                }}
                interestValues={interestValues}
                setInterestValues={setInterestValues}
              />
            ))}
          </div>
        </Accordion.Item>
        <Accordion.Item
          label={
            <header className="accordion-label-header">
              <span id="test-scores">Test Scores</span>
            </header>
          }
        >
          <p className="note pull-left">
            Note that there is a possibility to change scores manually. Removing all
              values is not possible. At least one value should be filled out.
          </p>
          {form.values.test_scores.map((score, index) => (
            <div
              key={index}
              css={css`
                display: flex;
                gap: 8px;
                align-items: center;
              `}
            >
              <Select
                label="Test Name"
                radius={16}
                data={[
                  { value: "English", label: "English" },
                  { value: "Reading", label: "Reading" },
                  { value: "Math", label: "Math" },
                ]}
                rightSection={<img src={arrowDown} alt="icon-select-expand"/>}
                styles={{
                  rightSection: { pointerEvents: "none" },
                  item: {
                    color: "rgba(0, 0, 0, 0.75)",
                    fontSize: 16,
                    fontWeight: 400,
                    borderRadius: 16,
                    "&:hover": {
                      background: "#F2F3FF",
                      color: "#6066A3",
                      fontWeight: 700,
                    },
                  },
                  input: {
                    height: 40,
                  },
                  label: {
                    color: "#6066A3",
                    transform: "translateY(13px) translateX(12px)",
                    zIndex: 3,
                    fontWeight: 300,
                    fontSize: 12,
                  },
                  error: {
                    display: "none",
                  },
                }}
                {...form.getListInputProps("test_scores", index, "test_title", {
                  type: "select",
                })}
                onChange={e => {
                  form.setListItem("test_scores", index, {
                    ...form.values.test_scores[index],
                    test_title: e,
                  });
                  form.validate();
                }}
              />
              <NumberInput
                label="Score"
                styles={{
                  wrapper: {
                    width: 62,
                  },
                  error: {
                    display: "none",
                  },
                }}
                min={0}
                max={100}
                {...form.getListInputProps("test_scores", index, "score")}
                onChange={e => {
                  form.setListItem("test_scores", index, {
                    ...form.values.test_scores[index],
                    score: e,
                  });
                  form.validate();
                }}
              />
              <TextInput
                disabled
                label="Date"
                {...form.getListInputProps("test_scores", index, "date")}
              />
              {form.values.test_scores.length > 1 && (
                <div
                  css={css`
                    align-self: end;
                    cursor: pointer;
                  `}
                  onClick={() => form.removeListItem("test_scores", index)}
                >
                  <img src={bin} alt="delete-score-icon" />
                </div>
              )}
            </div>
          ))}
        </Accordion.Item>
        <Accordion.Item
          label={
            <header className="accordion-label-header">
              <span id="web-search-history">Web Search History</span>
            </header>
          }
        >
          <p className="note pull-left">
            Note that there is a possibility to add value manually. Removing all
            values is not possible. At least one value should be filled out.
          </p>
          <div>
            {form.values.google_search_queries.map((_, index) => (
              <div
                key={index}
                css={css`
                  display: flex;
                  gap: 4px;
                `}
              >
                <TextInput
                  label="Web Search History"
                  css={css`
                    flex-basis: 90%;
                  `}
                  {...form.getListInputProps(
                    "google_search_queries",
                    index,
                    "query"
                  )}
                  onChange={e => {
                      const val = e.target.value;
                      form.setListItem("google_search_queries", index, {
                          ...form.values.google_search_queries[index],
                          query: val,
                      });
                      form.validate();
                  }}
                />
                {form.values.google_search_queries.length > 1 && (
                  <div
                    css={css`
                      align-self: end;
                      cursor: pointer;
                    `}
                    onClick={() =>
                      form.removeListItem("google_search_queries", index)
                    }
                  >
                    <img src={bin} alt="delete-score-icon" />
                  </div>
                )}
              </div>
            ))}
            <GhostButton
              css={css`
                border: 1px solid #6066a3;
                margin-top: 8px;
                width: 90%;
                font-size: 14px;
                color: #6066a3;
                height: 40px;
              `}
              onClick={() =>
                form.addListItem("google_search_queries", {
                  language: "ENGLISH",
                  timestamp: new Date()
                    .toISOString()
                    .replace(/[A-Z]/g, " ")
                    .trim(),
                  query: "",
                })
              }
            >
              + Add Web Search History Items
            </GhostButton>
          </div>
        </Accordion.Item>
        <Accordion.Item
          label={
            <header className="accordion-label-header">
              <span id="essays">Essays</span>
            </header>
          }
        >
          <p className="note pull-left">
            Note that there is a possibility to add value manually. Removing all
            values is not possible. At least one value should be filled out.
          </p>
          <div>
            {form.values.essays.map((_, index) => (
              <div
                key={index}
                css={css`
                  display: flex;
                  gap: 4px;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    flex-basis: 90%;
                    align-items: flex-end;
                  `}
                >
                  <Textarea
                    minRows={3}
                    label="Essay Text"
                    styles={{
                      input: {
                        borderRadius: 16,
                        border: "1px solid #E4E4E7",
                        color: "#404040",
                      },
                      label: {
                        color: "#6066A3",
                        transform: "translateY(13px) translateX(12px)",
                        zIndex: 3,
                        fontWeight: 300,
                        fontSize: 12,
                      },
                      invalid: {
                        color: "#404040",
                      },
                      error: {
                        display: "none",
                      },
                    }}
                    css={css`
                      width: 100%;
                      ${form.errors[`essays.${index}.text`]
                        ? "label { color: #C1292E; }"
                        : ""};
                    `}
                    {...form.getListInputProps("essays", index, "text")}
                    onChange={e => {
                      const val = e.target.value;
                      form.setListItem("essays", index, {
                        ...form.values.essays[index],
                        text: val,
                      });
                      form.validate();
                    }}
                  />
                  <p
                    css={css`
                      margin: 0;
                      font-size: 10px;
                      color: ${form.errors[`essays.${index}.text`]
                        ? "#C1292E"
                        : "#404040"};
                    `}
                  >
                    {
                      form.values.essays[index].text.split(" ").filter((x) => x)
                        .length
                    }
                    / 500 words
                  </p>
                </div>
                {form.values.essays.length > 1 && (
                  <div
                    css={css`
                      align-self: center;
                      cursor: pointer;
                    `}
                    onClick={() => form.removeListItem("essays", index)}
                  >
                    <img src={bin} alt="delete-score-icon" />
                  </div>
                )}
              </div>
            ))}
            <GhostButton
              css={css`
                border: 1px solid #6066a3;
                margin-top: 8px;
                width: 90%;
                font-size: 14px;
                color: #6066a3;
                height: 40px;
              `}
              onClick={() =>
                form.addListItem("essays", {
                  text: "",
                  date: new Date()
                    .toISOString()
                    .replace(/[A-Z]/g, " ")
                    .split(" ")[0],
                })
              }
            >
              + Add an Essay Text
            </GhostButton>
          </div>
        </Accordion.Item>
      </Accordion>
      <Button
        isLoading={isBusy}
        onClick={(e) => {
          e.preventDefault();
          if (!form.validate().hasErrors) {
            onProcessData();
          }
        }}
        css={css`
          height: 48px;
          width: 100%;
          margin-top: 21px;
        `}
      >
        {isBusy ? (
          <CircularLoading stroke="#fff" width={18} height={18} />
        ) : (
          "Submit"
        )}
      </Button>
      {error ? (
        <div className="error-block">
          {error}
        </div>
      ) : null}
    </Root>
  );
};
