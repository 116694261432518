/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useWindowScroll, useMediaQuery } from "@mantine/hooks";

import { Button } from "elements/button/Button";

import icon from "svg/logos/icon.svg";
import { scrollWithOffset } from "utils/dom-utils";

const HeaderRoot = styled.header`
  background: #fff;
  box-sizing: border-box;
  opacity: ${props => props.isInvisible ? 0 : 1};
  padding: ${(props) => (props.isCompact ? "12px 18px" : "12px 72px")};
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 9999;
  box-shadow: ${(props) =>
    props.addShadow ? "0px 12px 16px 6px rgba(0, 0, 0, 0.06)" : "none"};

  .header-logo-icon {
    height: 40px;
  }
`;

export const Header = ({ demoRef, isInvisible }) => {
  const [scroll] = useWindowScroll();
  const [applyShadow, setApplyShadow] = useState(false);
  const matches = useMediaQuery("(min-width: 800px)");

  useEffect(() => {
    if (scroll.y > window.innerHeight) {
      setApplyShadow(true);
    } else {
      setApplyShadow(false);
    }
  }, [scroll.y]);

  return (
    <HeaderRoot
        addShadow={applyShadow}
        className="header"
        isCompact={!matches}
        isInvisible={isInvisible}
    >
      <a href="https://myayo.world" target="_blank" rel="noopener">
        <img src={icon} className="header-logo-icon" alt="header-logo-icon" />
      </a>
      <Button
        css={css`
          height: 40px;
          padding: 7px 17px;
        `}
        as="a"
        onClick={() => {
          scrollWithOffset(demoRef.current);
        }}
      >
        Request a Demo
      </Button>
    </HeaderRoot>
  );
};
