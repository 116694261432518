import React from "react";
import styled from "@emotion/styled";

export const GhostButton = styled.button`
  cursor: pointer;
  box-sizing: border-box;
  padding: 12px 15px;
  background: transparent;
  color: #000;
  font-weight: 700;
  font-size: ${(props) => `${props.theme.typography.sizing.button}px`};
  line-height: 25px;
  text-align: center;
  border: 2px solid ${(props) => props.theme.colors["primary@500"]};
  border-radius: ${(props) => `${props.theme.radius}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color, color 0.2s ease;
  
  &:hover {
    background-color: ${props => props.theme.colors["primary@300"]};
    color: #fff;
  }
`;
