/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";

import { InfoBlock } from "elements/info-block/InfoBlock";
import { TextSkeleton } from "elements/text-skeleton/TextSkeleton";

import { getRandomNumbers } from "elements/road-map-correlation/RoadMapCorrelation";
import { Radar } from "elements/radar-chart/RadarChart";
import { Flex } from "elements/flex/Flex";

const data = getRandomNumbers(7, 2, 4);

export const AptitudesMap = () => (
  <InfoBlock withTilt>
    <h4>Student Leadership</h4>
    <Flex>
      <div
        css={css`
          margin-right: 16px;
          flex-basis: 40%;
        `}
      >
        <TextSkeleton value={"80"} />
        <TextSkeleton value={"80"} />
        <TextSkeleton value={"80"} />
        <TextSkeleton value={"80"} />
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <Radar width={80} height={80} data={data} />
      </div>
    </Flex>
  </InfoBlock>
);
