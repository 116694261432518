import React from "react";
import styled from "@emotion/styled";
import { useElementSize } from "@mantine/hooks";
import { LinePath } from "@visx/shape";
import { curveNatural, curveBasis } from "@visx/curve";
import { scaleLinear } from "@visx/scale";

import { InfoBlock } from "../info-block/InfoBlock";
import { TextSkeleton } from "../text-skeleton/TextSkeleton";

const Content = styled.div``;

const packCoords = (points) => points.map((x, i) => [i, x]);

export const getRandomNumbers = (n, min, max) =>
  Array.from({ length: n }, () => Math.random() * (max - min) + min);

const min = 0;
const max = 10;
const n = 7;
const dataPoints = [getRandomNumbers(n, min, max), getRandomNumbers(n, min, max)];

const padding = 15;

const x = scaleLinear({ domain: [0, n] });
const y1 = scaleLinear({ domain: [min, max] });
const y2 = scaleLinear({ domain: [min, max] });

export const RoadMapCorrelation = () => {
  const { ref, width } = useElementSize();
  x.range([0, width]);
  y1.range([0, 80]);
  y2.range([0, 80]);

  return (
    <InfoBlock withTilt>
      <h4>Tailored Path</h4>
      <Content ref={ref}>
        <TextSkeleton value={"45"} />
        <svg width={`${width}`} height="100">
          <LinePath
            curve={curveBasis}
            data={packCoords(dataPoints[0])}
            x={(d) => x(d[0])}
            y={(d) => y1(d[1]) + padding}
            stroke="#6066A3"
            strokeWidth={2}
            strokeOpacity={1}
            shapeRendering="geometricPrecision"
          />
          <LinePath
            curve={curveBasis}
            data={packCoords(dataPoints[1])}
            x={(d) => x(d[0])}
            y={(d) => y2(d[1]) + padding}
            stroke="#F3AA47"
            strokeWidth={2}
            strokeOpacity={1}
            shapeRendering="geometricPrecision"
          />
        </svg>
      </Content>
    </InfoBlock>
  );
};
