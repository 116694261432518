import book from "../svg/icons/book.svg";
import masks from "../svg/icons/masks.svg";
import protractor from "../svg/icons/measure-appliances.svg";
import jigsaw from "../svg/icons/jigsaw.svg";

export const reasons = [
  [
    {
      icon: book,
      headline: "Holistic Student Profile",
      text: `AYO builds a holistic student profile combining student data scattered across dozens of systems. Parents and teachers play important roles in filling the gaps in students' data too.`,
    },
    {
      icon: masks,
      headline: "AI-Powered Platform",
      text: `AYO uniquely enriches student profile through student interactions with AYO and uses the collected data to share insights about individual's interests, aptitudes, and potential.`,
    },
  ],
  [
    {
      icon: protractor,
      headline: "Tailored Learning",
      text: `Empowers students to build agency over their learning while educators obtain all
the data they need to tailor the learning
path for each student.`,
    },
    {
      icon: jigsaw,
      headline: "Uniquely Yours",
      text: `AYO can be customized to suit the needs of your district and work with your applications to get those valuable student insights and build specific features for your students to help them thrive.`,
    },
  ],
];
