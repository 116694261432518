import styled from "@emotion/styled";

export const Tag = styled.span`
  display: inline-block;
  font-size: 14px;
  line-height: 22px;
  padding: ${props => props.isLarge? "8px 12px" : "0 8px"};
  border: ${(props) =>
    props.themeColor ? `1px solid ${props.themeColor}` : "1px solid #E4E4E7"};
  box-sizing: border-box;
  border-radius: 6px;
  margin: 0 4px;
  white-space: nowrap;
  color: ${(props) => (props.themeColor ? props.themeColor : "#404040")};
  cursor: pointer;
  max-width: ${props => props.width ? `${props.width}px` : "136px"};
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
`;
